import moment from 'moment';

// Will by default give all years from 1900 to 2100
export const yearRange = (startAt = 1900, endAt = 2100) => {
  if (!startAt || !endAt) {
    return [];
  }
  let startVar = startAt;
  let endVar = endAt;
  if (!Number.isInteger(startAt)) {
    startVar = moment(startAt).year();
  }
  if (!Number.isInteger(endAt)) {
    endVar = moment(endAt).year();
  }
  // incudes 1 year offset for years beyond min and max years
  const size = endVar - startVar + 3;
  return [...Array(size).keys()].map(i => i + startAt - 1);
};
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
