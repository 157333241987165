import React from 'react';
import ReviewItem from 'components/fc-self-service/components/review-item';
import Question from 'components/fc-self-service/components/question';
import { STRINGS } from '../../fc_ss_constants';

const PickUpOrDelivery = (props: any) => {
  const { nextQuestion, isReview, currentAnswers, onEdit, saveAnswer, answerOptions, isEdit } =
    props;
  const keyName = 'pickup_or_delivery';
  const questionText = 'How do you want to get your medications?';

  const handleResponse = (value: any) => {
    if (value === currentAnswers.pickup_or_delivery) {
      nextQuestion();
      return;
    }
    const responseValue: { keyName: string; value: string | number | null | {} } = {
      keyName,
      value: value === 'delivery' ? STRINGS.delivery : STRINGS.pickup,
    };
    saveAnswer(responseValue);
    if (value === 'pickup') {
      if (isEdit && !answerOptions.pickup_dates) {
        nextQuestion();
      } else if (answerOptions.pickup_dates) {
        nextQuestion('pu_or_delivery_details');
      } else {
        nextQuestion('payment_method');
      }
    } else {
      nextQuestion('pu_or_delivery_details', true);
    }
  };
  const orderContainsCAMedication = currentAnswers.therapies.some(
    (therapy: any) => therapy.included && therapy.clinic_administered,
  );

  const returnReview = () =>
    !orderContainsCAMedication ? (
      <ReviewItem
        questionText={questionText}
        onEdit={onEdit}
        currentAnswer={currentAnswers[keyName]}
        keyName={keyName}
      />
    ) : null;

  return isReview ? (
    returnReview()
  ) : (
    <Question
      questionText={questionText}
      response={handleResponse}
      keyName={keyName}
      buttons={{
        button1Text: 'Delivery',
        button1Value: 'delivery',
        button2Text: 'Pickup',
        button2Value: 'pickup',
      }}
    />
  );
};

export default PickUpOrDelivery;
