import React from 'react';
import { withStyles, WithStyles } from '@mui/styles';
import { Grid, Typography, Divider, Button } from '@mui/material';
import { buildQaId } from 'utils/build-qa-id';
import { windowFeatureIsEnabled } from 'config/window-features';
import { useDispatch } from 'react-redux';
import { ShippingStyles } from './shipping.styles';
import { IPrintLabelButton } from './types';
import { printLabel } from './print-label';

const qaIdBuilder = buildQaId('shipping');

interface IShippingProps extends IPrintLabelButton, WithStyles<typeof ShippingStyles> {}

const ReprintLabelButton = (props: IShippingProps): JSX.Element | null => {
  const { classes, orderId, setPrintLabelClicked, printDetails, setShowManualReprintLabelMessage } =
    props;
  const dispatch = useDispatch();
  const deliveryMethod = props.currentDeliveryInformation?.deliveryMethod;
  const print_courier_label_is_enabled = windowFeatureIsEnabled('courier_label');

  return (
    <>
      {printDetails && (
        <Grid item className={classes.reprintGrid}>
          <Divider />
          <Typography style={{ marginBottom: 15 }} className={classes.reprintLabelText}>
            {printDetails}
          </Typography>
        </Grid>
      )}
      <Grid item className={classes.printLabelButton}>
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            const response = await printLabel({
              orderId,
              dispatch,
              deliveryMethod,
              setPrintLabelClicked,
            });
            if (response.nuVizzOrder && !print_courier_label_is_enabled) {
              setShowManualReprintLabelMessage && setShowManualReprintLabelMessage(true);
            }
          }}
          data-qa-id={qaIdBuilder('button.reprint-label')}
        >
          Reprint Label
        </Button>
      </Grid>
    </>
  );
};

export default withStyles(ShippingStyles)(ReprintLabelButton);
