import React from 'react';
import { BaseTable } from 'containers/application-manager/base-table/base-table';
import { IModalResponse, IModalResult } from './types';
import { IHeaderCell } from '../../../base-table/types';

interface ITableProps {
  dataToList: IModalResult[];
}

export const ModalDataTable: React.FC<ITableProps> = (props): JSX.Element => {
  const columnSettings: IHeaderCell<IModalResult, undefined>[] = [
    {
      label: 'GPI',
      parentKey: 'gpi',
      hideLeftPadding: true,
      sortable: true,
    },
    {
      label: 'Drug Name',
      parentKey: 'drug_name',
      hideLeftPadding: true,
      sortable: true,
    },
    {
      label: 'Form',
      parentKey: 'dosage_form_description',
      hideLeftPadding: true,
      sortable: true,
    },
    {
      label: 'Dose',
      parentKey: 'dose',
      hideLeftPadding: true,
      sortable: true,
    },
    {
      label: 'LDD',
      parentKey: 'ldd',
      hideLeftPadding: true,
      sortable: true,
    },
    {
      label: 'Specialty Type',
      parentKey: 'specialty_type',
      hideLeftPadding: true,
      sortable: true,
    },
    {
      label: 'NDC',
      parentKey: 'ndc',
      hideLeftPadding: true,
      sortable: true,
    },
  ];

  const getSupportiveCareData = React.useMemo<IModalResponse | undefined>(() => {
    const supportiveCareData = props.dataToList;
    if (supportiveCareData.length) {
      const dataToShow = supportiveCareData.map(item => {
        return {
          ...item,
        };
      }, [] as IModalResult[]);
      return { totalCount: dataToShow.length, results: dataToShow };
    }
    return undefined;
  }, [props.dataToList]);

  return (
    <>
      <BaseTable<IModalResponse, IModalResult, undefined>
        actionsPermitted
        dataSet={getSupportiveCareData}
        columnSettings={columnSettings}
        orderByDefaultParent="id"
        paginationQueryParamSettings={{
          pageSizeQueryStringKey: 'pageSize',
          pageNumberQueryStringKey: 'pageNumber',
          searchTermQueryStringKey: 'searchTerm',
          sortPropQueryStringKey: 'sortProp',
          sortOrderQueryStringKey: 'sortOrder',
        }}
        searchTerm={undefined}
        childPkSelector={() => ''}
        parentPkSelector={p => p.id}
        qaId="supportive-care-plan-modal-table"
        customRowsPerPageOptions={[5, 10]}
      />
    </>
  );
};
