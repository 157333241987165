import React from 'react';
import { TextField } from '@mui/material';
import ReviewItem from 'components/fc-self-service/components/review-item';
import { ClinicalQuestion } from '../../components/question';
import FillCoordinationFieldNames from './fill_coordination_field_names';

const YesNoTemplate = (props: any) => {
  const {
    saveAnswer,
    isReview,
    currentAnswers,
    onEdit,
    questionText,
    keyName,
    nextQuestion,
    subQText,
  } = props;

  const saveAnswerCurried = (value: any) => {
    const x = [
      { keyName, value: value.value },
      { keyName: `${keyName}_details`, value: value.details || null },
    ];
    saveAnswer(x);
    nextQuestion();
  };
  const patient_supplied_details = currentAnswers?.clinical_responses?.[`${keyName}_details`];
  return isReview ? (
    <ReviewItem
      questionText={`${questionText}?`}
      subQText={subQText}
      onEdit={onEdit}
      currentAnswer={currentAnswers[keyName] ? 'Yes' : 'No'}
      keyName={keyName}
    >
      {patient_supplied_details ? (
        <TextField style={{ width: '100%', marginTop: '5px' }} value={patient_supplied_details} />
      ) : null}
    </ReviewItem>
  ) : (
    <ClinicalQuestion
      currentAnswer={currentAnswers?.clinical_responses?.[`${keyName}_details`] || null}
      questionText={`${questionText}?`}
      subQText={subQText}
      response={saveAnswerCurried}
      keyName={keyName}
      buttons={{ button1Text: 'Yes', button2Text: 'No' }}
    />
  );
};

export const NewAllergies = (props: any) => {
  const { saveAnswer, isReview, currentAnswers, onEdit, nextQuestion } = props;
  const keyName = FillCoordinationFieldNames.New_Allergies;
  const propsOfInterest = { saveAnswer, isReview, currentAnswers, onEdit, keyName, nextQuestion };
  const qText = 'Do you have any new allergies';
  const subQText =
    'Please enter the new allergy here. If you have any questions or you need to talk to the pharmacist about your new allergy, we will contact you. Feel free to reach out to us if you would like to discuss it immediately.';
  return <YesNoTemplate {...propsOfInterest} questionText={qText} subQText={subQText} />;
};

export const SideEffects = (props: any) => {
  const { saveAnswer, isReview, currentAnswers, onEdit, nextQuestion } = props;
  const keyName = FillCoordinationFieldNames.Side_Effects;
  const propsOfInterest = { saveAnswer, isReview, currentAnswers, onEdit, keyName, nextQuestion };
  const qText = 'Have you experienced any side effects since last time we spoke';
  const subQText =
    'Please describe side effects here; if you think you know what medication is causing the side effect, please include that as well. If we have any questions or the pharmacist needs talk to you about these side effects, we will contact you. Feel free to reach out to us if you would like to discuss them immediately.';
  return <YesNoTemplate {...propsOfInterest} questionText={qText} subQText={subQText} />;
};

export const MedicationChanges = (props: any) => {
  const { saveAnswer, isReview, currentAnswers, onEdit, nextQuestion } = props;
  const keyName = FillCoordinationFieldNames.Changes_In_Medication;
  const propsOfInterest = { saveAnswer, isReview, currentAnswers, onEdit, keyName, nextQuestion };
  const qText = 'Are there any changes to your medications';
  const subQText =
    'Please enter medication changes here. If we have any questions or the pharmacist needs talk to you about your medication changes, we will contact you. Feel free to reach out to us if you would like to discuss them immediately.';
  return <YesNoTemplate {...propsOfInterest} questionText={qText} subQText={subQText} />;
};

export const MissedDoses = (props: any) => {
  const { saveAnswer, isReview, currentAnswers, onEdit, nextQuestion } = props;
  const keyName = FillCoordinationFieldNames.Patient_Missed_Doses;
  const propsOfInterest = { saveAnswer, isReview, currentAnswers, onEdit, keyName, nextQuestion };
  const qText = 'Have you missed any doses of medication';
  const subQText =
    'Please enter the medication and number of missed doses since your last refill. If we have any questions or the pharmacist needs talk to you about your missed doses, we will contact you. Feel free to reach out to us if you would like to discuss them immediately.';
  return <YesNoTemplate {...propsOfInterest} questionText={qText} subQText={subQText} />;
};

export const PatientQuestions = (props: any) => {
  const { saveAnswer, isReview, currentAnswers, onEdit, nextQuestion } = props;
  const keyName = FillCoordinationFieldNames.Patient_Questions;
  const propsOfInterest = { saveAnswer, isReview, currentAnswers, onEdit, keyName, nextQuestion };
  const qText = 'Do you have any questions for the pharmacist';
  const subQText =
    'Please enter your question here. A pharmacist will reach out to you as soon as possible to answer your question. Feel free to reach out to us if you would like to discuss them immediately.';
  return <YesNoTemplate {...propsOfInterest} questionText={qText} subQText={subQText} />;
};

export const NewConditions = (props: any) => {
  const { saveAnswer, isReview, currentAnswers, onEdit, nextQuestion } = props;
  const keyName = FillCoordinationFieldNames.New_Medical_Conditions;
  const propsOfInterest = { saveAnswer, isReview, currentAnswers, onEdit, keyName, nextQuestion };
  const qText = 'Do you have any new medical conditions';
  const subQText =
    'Please enter any new medical conditions here. If we have any questions or the pharmacist needs talk to you about your new medical conditions, we will contact you. Feel free to reach out to us if you would like to discuss them immediately.';
  return <YesNoTemplate {...propsOfInterest} questionText={qText} subQText={subQText} />;
};

export const HospitalVisits = (props: any) => {
  const { saveAnswer, isReview, currentAnswers, onEdit, nextQuestion } = props;
  const keyName = FillCoordinationFieldNames.Hospital_Visit;
  const propsOfInterest = { saveAnswer, isReview, currentAnswers, onEdit, keyName, nextQuestion };
  const qText =
    'Have you been hospitalized or gone to the emergency department since we last spoke';
  const subQText =
    'Please enter dates and reason for hospitalization or ER visit here. If we have any questions or the pharmacist needs talk to you about your hospitalization, we will contact you. Feel free to reach out to us if you would like to discuss them immediately.';
  return <YesNoTemplate {...propsOfInterest} questionText={qText} subQText={subQText} />;
};

export const NewInfections = (props: any) => {
  const { saveAnswer, isReview, currentAnswers, onEdit, nextQuestion } = props;
  const keyName = FillCoordinationFieldNames.New_Infections;
  const propsOfInterest = { saveAnswer, isReview, currentAnswers, onEdit, keyName, nextQuestion };
  const qText = 'Have you experienced any infections since we last spoke';
  const subQText = 'Please enter the details of any new infections here.';
  return <YesNoTemplate {...propsOfInterest} questionText={qText} subQText={subQText} />;
};
