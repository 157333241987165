import React, { Fragment } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { renderDatePicker as RenderDatePicker } from 'components/form/datepicker/datetime-picker';
import { CircleIndicatorIcon } from 'components/icons/icons';
import { getTherapyStatusById } from 'services/utils/therapy-service';
import { Controller } from 'react-hook-form';
import { convertToArborDate } from 'models/time/arbor-date';
import 'components/form/datepicker/datetime-picker.css';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 10,
    backgroundColor: theme.palette.primary.bluesky,
    padding: 10,
  },
  indicatorLabel: {
    'fontWeight': 'bold',
    'marginLeft': theme.spacing(1),
    'marginRight': theme.spacing(4),
    '& i': {
      marginRight: theme.spacing(0.5),
    },
  },
  title: {
    marginBottom: theme.spacing(0.5),
  },
  error: {
    color: theme.palette.primary.valenciaRed,
    fontSize: 10,
    margin: 0,
  },
}));

const PatientReenrollTherapyHeader = props => {
  const { therapyId, selectedTasks, updateTherapyNeedsbyDate, control, errors } = props;
  const classes = useStyles();
  const theme = useTheme();

  const therapy = useSelector(state => state?.therapies?.data[therapyId]);
  const therapyAdministrationStatuses = useSelector(
    state => state?.therapyStatuses?.therapyAdministrationStatuses || [],
  );

  const adminStatus = getTherapyStatusById(
    therapyAdministrationStatuses,
    therapy.administration_status_id,
  );

  return (
    <div className={classes.container}>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item xs>
          <Typography variant="h6" className={classes.title}>
            {therapy.drug_name}
          </Typography>
        </Grid>

        <Grid item xs>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Typography variant="body2">Needs By Date:</Typography>
            </Grid>

            <Grid item data-qa-id="patient-rennroll-therapy-header-needs-by-date">
              <Controller
                control={control}
                name={`${therapyId}-needsbydate`}
                rules={{ required: Boolean(Object.values(selectedTasks || {}).length) }}
                render={ctrlProps => (
                  <RenderDatePicker
                    placeholderText="mm/dd/yyyy"
                    onChange={e => {
                      ctrlProps?.field?.onChange(e);
                      updateTherapyNeedsbyDate(convertToArborDate(e).getUtcDate());
                    }}
                    input={{
                      value: ctrlProps?.field?.value,
                      onChange: ctrlProps?.field?.onChange,
                      onBlur: ctrlProps?.field?.onBlur,
                    }}
                    // onBlur={ctrlProps?.field?.onBlur}
                    meta={{ touched: false, error: false, warning: false }}
                    selected={ctrlProps?.field?.value}
                  />
                )}
              />

              {errors[`${therapyId}-needsbydate`] && (
                <Typography className={classes.error}>REQUIRED</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="flex-start">
        <Grid item>
          <Typography variant="body2">Administration:</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" className={classes.indicatorLabel}>
            <CircleIndicatorIcon
              size={12}
              color={theme.palette.map.therapyAdministration[adminStatus.status]}
            />
            {adminStatus.status}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">Dispensing:</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" className={classes.indicatorLabel}>
            <CircleIndicatorIcon
              size={12}
              color={theme.palette.map.therapyEnrollment[therapy.dispensing_status]}
            />
            {therapy.dispensing_status}
          </Typography>
        </Grid>
        {!!therapy.is_specialty && (
          <>
            <Grid item>
              <Typography variant="body2">Clinical Support:</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" className={classes.indicatorLabel}>
                <CircleIndicatorIcon
                  size={12}
                  color={theme.palette.map.therapyEnrollment[therapy.clinical_support_status]}
                />
                {therapy.clinical_support_status}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default PatientReenrollTherapyHeader;
