import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';
import compose from 'recompose/compose';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import {
  ADD_TASK_OUTREACH_FORM,
  OUTREACH_DEPRECATED_TYPES,
  THERAPY_ADMIN_STATUS_DISCONTINUED,
  THERAPY_ADMIN_STATUS_NO_GO,
} from 'constants/index';
import { OutreachServiceEnrollment, OutreachTypes } from 'constants/enums';
import { workListChanged } from 'actions/action-patient';
import { fetchTaskCounts, addOutreachTask, addOutreachTaskPatient } from 'actions/action-tasks';
import { convertToArborDate } from 'models/time/arbor-date';
import { TherapyUtil } from 'utils/therapy-util';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { ReactSelectForReduxSingle } from 'components/form/field/react-select';
import { getInitialServiceGroupForReactSelect } from 'services/utils/task-service';
import Validation, { validateDateTime, required } from 'components/form/validation/validation';
import RadioButton from 'components/form/radio/radio-button';
import RadioGroup from 'components/form/radio/radio-group';
import { renderTextField, renderMultipleDropdown } from 'components/form/field/redux-field';
import colors from '../../../lib/themes/colors.module.scss';
import { styles } from '../task-detail-styles';

const { deepBlue2 } = colors;

class AddOutreachForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceGroupDropdown: -1,
    };
    this.handleServiceGroupDropdown = this.handleServiceGroupDropdown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleServiceGroupDropdown(e) {
    this.setState({
      serviceGroupDropdown: e.target.value,
    });
  }

  // Handles the submit functionality. Right now it sets some fields to null for simplicity's sake
  handleSubmit(values) {
    const {
      addOutreachTask, // eslint-disable-line
      fetchTaskCounts, //eslint-disable-line
      addOutreachTaskPatient, // eslint-disable-line
      workListChanged, // eslint-disable-line
      reset,
      therapy,
      cancel,
      selectedPatientId,
    } = this.props;
    const payload = {
      ...values,
      followup_dt: convertToArborDate(values.followup_dt).getUtcDatetime(),
      patient_id: selectedPatientId,
      category: values.category,
      therapy_id: therapy && therapy.id ? therapy.id : null,
      type_id: values.type_id,
      subtype: values.subtype ? values?.subtype?.value : null,
      therapy_ids: values.therapy_ids || null,
      status_id: 13001,
      service_group_id: values.service_group_id.value,
      is_patient: therapy && therapy.id ? 0 : 1,
    };
    if (payload.is_patient) {
      return addOutreachTaskPatient(payload).then(() => {
        workListChanged();
        cancel();
        reset();
      });
    }

    return addOutreachTask(payload).then(() => {
      workListChanged();
      fetchTaskCounts();
      cancel();
      reset();
    });
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      classes,
      cancel,
      taskStatuses,
      lookups,
      categoryVal,
      typeVal,
      buttonIdPrefix,
      therapies,
    } = this.props;

    const discontinuedStatus = [THERAPY_ADMIN_STATUS_DISCONTINUED, THERAPY_ADMIN_STATUS_NO_GO];

    const therapiesArray = therapies?.data
      ? Object.values(therapies.data).filter(
          t => !discontinuedStatus.includes(t.administration_status),
        )
      : [];
    const therapiesListWithDosage = TherapyUtil.therapiesListWithDosage(therapiesArray);

    const { serviceGroupDropdown } = this.state;

    const radioProps = {
      width: '100%',
      radius: 5,
      checkedColor: deepBlue2,
    };

    const renderType = ({ input, meta: { touched, error, warning } }) => (
      <Grid container>
        <RadioGroup
          horizontal
          name="category"
          id="category"
          {...input}
          className={classes.radioGroup}
        >
          {taskStatuses.outreach_types
            .filter(
              outreachType =>
                outreachType.category_id === categoryVal &&
                !OUTREACH_DEPRECATED_TYPES.includes(outreachType.name.toLowerCase()),
            )
            .map(type => (
              <RadioButton {...radioProps} value={type.id} key={type.id}>
                {type.name}
              </RadioButton>
            ))}
        </RadioGroup>
        <Validation touched={touched} error={error} warning={warning} />
      </Grid>
    );
    const renderCategory = ({ input, meta: { touched, error, warning } }) => (
      <Grid container>
        <RadioGroup
          horizontal
          name="category"
          id="category"
          {...input}
          className={classes.radioGroup}
        >
          {taskStatuses.outreach_categories.map(subtype => (
            <RadioButton {...radioProps} value={subtype.id} key={subtype.id}>
              {subtype.name}
            </RadioButton>
          ))}
        </RadioGroup>
        <Validation touched={touched} error={error} warning={warning} />
      </Grid>
    );

    return (
      <Grid container justifyContent="center" spacing={7}>
        <Grid item xs={12}>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            className={classes.addFormContainer}
            autoComplete="off"
          >
            <Grid container className={classes.formRowContainer}>
              <Grid item xs={12} className={classes.fieldContainer}>
                <Field name="category" component={renderCategory} validate={[required]} />
              </Grid>
              <Grid item xs={12} className={classes.fieldContainer}>
                <Field name="type_id" component={renderType} validate={[required]} />
              </Grid>
              {typeVal === OutreachServiceEnrollment.Liason && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="subtype"
                    label="Service Enrollment Type *"
                    validate={[required]}
                    component={ReactSelectForReduxSingle}
                    fields={taskStatuses.outreach_types
                      .filter(
                        outreachType =>
                          outreachType.category_id === OutreachTypes.PharmacistSubtype,
                      )
                      .map(t => ({
                        label: t.name,
                        value: t.id,
                      }))}
                  />
                </Grid>
              )}
              {typeVal === OutreachServiceEnrollment.Pharmacist && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="subtype"
                    label="Service Enrollment Type *"
                    validate={[required]}
                    component={ReactSelectForReduxSingle}
                    fields={taskStatuses.outreach_types
                      .filter(
                        outreachType => outreachType.category_id === OutreachTypes.LiasonSubtype,
                      )
                      .map(t => ({
                        label: t.name,
                        value: t.id,
                      }))}
                  />
                </Grid>
              )}
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="service_group_id"
                  label="Service Group *"
                  validate={[required]}
                  component={ReactSelectForReduxSingle}
                  fields={lookups.serviceGroups.map(int => ({
                    label: int.display_name || int.name,
                    value: int.id,
                  }))}
                  state={serviceGroupDropdown}
                />
              </Grid>
              <Grid item xs={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="followup_dt"
                  label="Follow up Date *"
                  component={renderDatePicker}
                  validate={[validateDateTime, required]}
                />
              </Grid>
              <Grid item xs={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="outreach_description"
                  label="Outreach Description"
                  component={renderTextField}
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={6} className={classes.fieldContainer}>
                <Field
                  name="therapy_ids"
                  label="Therapies"
                  component={renderMultipleDropdown}
                  fields={therapiesListWithDosage}
                />
              </Grid>
            </Grid>

            {/* Eighth Row */}
            <ConfirmationPanel
              cancelButtonName="edit_therapy_cancel_button"
              submitButtonName="edit_therapy_submit_button"
              handleCancel={cancel}
              disableSubmit={pristine || submitting}
              buttonIdPrefix={buttonIdPrefix}
            />
          </form>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state, props) {
  const { taskStatuses, selectedPatientId, lookups, therapies } = state;
  const { therapy } = props;
  const selector = formValueSelector(ADD_TASK_OUTREACH_FORM);
  const categoryVal = selector(state, 'category');
  const typeVal = selector(state, 'type_id');

  return {
    categoryVal,
    therapies,
    typeVal,
    taskStatuses,
    selectedPatientId,
    lookups,
    initialValues: {
      followup_dt: null,
      status_id: null,
      therapy_id: null,
      category: null,
      type_id: null,
      subtype: null,
      therapy_ids: therapy ? `${therapy.id}` : null,
      service_group_id: getInitialServiceGroupForReactSelect(
        props.defaultServiceGroup,
        props.serviceGroups,
      ),
    },
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    fetchTaskCounts,
    workListChanged,
    addOutreachTask,
    addOutreachTaskPatient,
  }),
)(reduxForm({ form: ADD_TASK_OUTREACH_FORM })(AddOutreachForm));
