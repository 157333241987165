import React from 'react';
import { withStyles, WithStyles } from '@mui/styles';
import { buildQaId } from 'utils/build-qa-id';
import { useDispatch } from 'react-redux';
import { Grid, Button } from '@mui/material';
import { editTask } from 'actions/action-tasks';
import { notifyError } from 'actions/action-notifications';
import { windowFeatureIsEnabled } from 'config/window-features';
import { FillDeliveryConfirmationStatus } from 'interfaces/enums/TaskStatuses/FillDeliveryConfirmationStatus';
import { IPrintLabelButton } from './types';
import { ShippingStyles } from './shipping.styles';
import { printLabel } from './print-label';
import { FDC } from '../../constants/index';

const qaIdBuilder = buildQaId('shipping');

interface IShippingProps extends IPrintLabelButton, WithStyles<typeof ShippingStyles> {}

const PrintLabelButton = (props: IShippingProps): JSX.Element | null => {
  const {
    classes,
    orderId,
    setPrintLabelClicked,
    currentFdcInformation,
    setShowManualPrintLabelMessage,
    disabled,
  } = props;
  const dispatch = useDispatch();
  const deliveryMethod = props.currentDeliveryInformation?.deliveryMethod;
  const print_courier_label_is_enabled = windowFeatureIsEnabled('courier_label');

  const getPrintLabel = async () => {
    try {
      const response = await printLabel({
        orderId,
        dispatch,
        deliveryMethod,
        setPrintLabelClicked,
      });

      if (response.nuVizzOrder && !print_courier_label_is_enabled) {
        setShowManualPrintLabelMessage && setShowManualPrintLabelMessage(true);
      }

      const updatedTask = {
        id: currentFdcInformation?.id,
        taskType: FDC,
        therapy_id: currentFdcInformation?.therapyId,
        status_id: FillDeliveryConfirmationStatus.Shipped,
      };
      dispatch && dispatch(editTask(updatedTask, FDC));
    } catch (error) {
      console.log('getPrintLabel error: ', error);
      const notifyMessage = 'Unable to print label.';
      dispatch(notifyError(notifyMessage));
    }
  };

  return (
    <Grid item className={classes.printLabelButton}>
      <Button
        color="primary"
        variant="contained"
        onClick={async () => {
          return getPrintLabel();
        }}
        data-qa-id={qaIdBuilder('button.print-label')}
        disabled={disabled}
      >
        Print Label
      </Button>
    </Grid>
  );
};

export default withStyles(ShippingStyles)(PrintLabelButton);
