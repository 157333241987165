import React, { useEffect } from 'react';
import { ShippingClient } from 'clients/shipping-client';
import { notifyError } from 'actions/action-notifications';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { PharmacyService } from 'services/utils/pharmacy-service';
import { DeliveryMethodValue, CourierType } from 'constants/enums';
import { FillDeliveryConfirmationStatus } from 'interfaces/enums/TaskStatuses/FillDeliveryConfirmationStatus';
import { Grid } from '@mui/material';
import PrintShippingButtons from './print-shipping-buttons';
import PickupShippingButtons from './pickup-shipping-buttons';
import ContentsOfPackage from './contents-of-package';
import DeliveryInformation from './delivery-information';
import DeliveryConfirmation from './delivery-confirmation';
import PaymentInformation from './payment-information';
import { IComponentStateItem } from './types';
import { useLabelScan } from './use-shipping-scan';
import { windowFeatureIsEnabled } from '../../config/window-features';

interface IFillDeliveryConfirmationOrderDetailsProps {
  orderId: number;
  statusId: number;
}

export function FillDeliveryConfirmationOrderDetails(
  props: IFillDeliveryConfirmationOrderDetailsProps,
): JSX.Element {
  const { orderId, statusId } = props;

  const [pharmacyService] = React.useState<PharmacyService>(new PharmacyService());
  const [fdcOrderDetails, setFdcOrderDetails] = React.useState<IComponentStateItem>();
  const [isLabLogistics, setIsLabLogistics] = React.useState<boolean>(false);
  const [isSpokeLogistics, setIsSpokeLogistics] = React.useState<boolean>(false);
  const [isAssociatedCourier, setIsAssociatedCourier] = React.useState<boolean>(false);
  const [isNuVizzCourier, setIsNuVizzCourier] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();

  const scanProps = useLabelScan({ disableScanner: true });

  useEffect(() => {
    async function getFCData() {
      try {
        const response = await ShippingClient.fetch(
          orderId.toString(),
          'fill-delivery-confirmation-order-details',
        );
        if (response?.data?.error) {
          dispatch(notifyError(response.data?.error));
        } else {
          setFdcOrderDetails(response.data);
          if (windowFeatureIsEnabled('shipping_verification')) {
            await scanProps.fetchHistory(orderId.toString());
          }
          const currentDispensingPharmacyNpi =
            response.data?.deliveryInformation?.dispensingPharmacyNpi;
          if (currentDispensingPharmacyNpi) {
            const pharmacyResponse = await pharmacyService.getPharmacyByNpi(
              currentDispensingPharmacyNpi,
            );
            setIsLabLogistics(pharmacyResponse?.lab_logistics_customer_id !== null);
            setIsSpokeLogistics(pharmacyResponse?.spoke_logistics_customer_id !== null);
            setIsAssociatedCourier(pharmacyResponse?.associated_courier_customer_id !== null);
            setIsNuVizzCourier(pharmacyResponse?.nuvizz_courier_customer_id !== null);
          }
        }
      } catch (_error) {
        dispatch(notifyError('Failed to load order details.'));
      }
    }

    if (orderId && fdcOrderDetails == null) {
      getFCData();
    }
  }, [orderId, fdcOrderDetails, dispatch]);

  const {
    ancillarySupplies,
    deliveryInformation,
    paymentInformation,
    printInformation,
    therapiesNames,
    fdcInformation,
  } = fdcOrderDetails ?? {};

  const isCurrentMethodOfDeliveryUps =
    deliveryInformation?.deliveryMethod === DeliveryMethodValue.Ups;
  const isCurrentMethodOfDeliveryCourier =
    deliveryInformation?.deliveryMethod === DeliveryMethodValue.Courier;

  const isExternalCourier =
    isCurrentMethodOfDeliveryCourier &&
    (fdcOrderDetails?.deliveryInformation?.courierType === CourierType.External ||
      !windowFeatureIsEnabled('courier_type'));

  const isInternalCourier =
    isCurrentMethodOfDeliveryCourier &&
    windowFeatureIsEnabled('courier_type') &&
    fdcOrderDetails?.deliveryInformation?.courierType === CourierType.Internal;

  const displayPrintLabelButton =
    isCurrentMethodOfDeliveryUps ||
    (isExternalCourier && isLabLogistics) ||
    (isExternalCourier && isSpokeLogistics) ||
    (isExternalCourier && isAssociatedCourier) ||
    (isExternalCourier && isNuVizzCourier);

  const isCourierWithMissingConfiguration =
    isExternalCourier &&
    !isLabLogistics &&
    !isSpokeLogistics &&
    !isAssociatedCourier &&
    !isNuVizzCourier;

  const displayPickUpButton = deliveryInformation?.deliveryMethod === DeliveryMethodValue.PickUp;

  const isDispense = fdcInformation?.statusId === FillDeliveryConfirmationStatus.Dispense;
  const displayButtons = (): JSX.Element => {
    return (
      <>
        {displayPrintLabelButton && (
          <PrintShippingButtons
            currentDeliveryInformation={deliveryInformation}
            currentPrintInformation={printInformation}
            currentFdcInformation={fdcInformation}
            statusId={statusId}
            orderId={orderId?.toString()}
            setFdcOrderDetails={setFdcOrderDetails}
          />
        )}
        {displayPickUpButton && (
          <PickupShippingButtons
            currentDeliveryInformation={deliveryInformation}
            currentPrintInformation={printInformation}
            orderId={orderId?.toString()}
            currentFdcInformation={fdcInformation}
          />
        )}
      </>
    );
  };

  return (
    <>
      <DeliveryConfirmation
        currentDeliveryInformation={deliveryInformation}
        currentPrintInformation={printInformation}
      />
      <ContentsOfPackage
        currentMedicationInformation={therapiesNames}
        currentAncillarySupply={ancillarySupplies}
        orderId={orderId}
        scanProps={windowFeatureIsEnabled('shipping_verification') ? scanProps : undefined}
        isPickup={deliveryInformation?.deliveryMethod === DeliveryMethodValue.PickUp}
      />
      <PaymentInformation currentPaymentInformation={paymentInformation} />
      <DeliveryInformation
        currentDeliveryInformation={deliveryInformation}
        currentPrintInformation={printInformation}
        currentFdcInformation={fdcInformation}
        scanProps={windowFeatureIsEnabled('shipping_verification') ? scanProps : undefined}
        isMissingCourierConfiguration={isCourierWithMissingConfiguration}
      />

      <Grid container style={{ gap: 20 }} item>
        {isDispense || isInternalCourier ? null : displayButtons()}
      </Grid>
    </>
  );
}
