import React from 'react';
import { Grid } from '@mui/material';

const SecureLinkClinicalResponse = (props: any) => {
  const { key_name, comments } = props;
  const value = comments[`${key_name}_details`];
  if (value) {
    return (
      <Grid xs={12} style={{ padding: '16px', paddingRight: '34px' }}>
        <Grid
          xs={12}
          style={{
            backgroundColor: '#EEF4F9',
            padding: '16px',
            borderRadius: '4px',
            color: '#44484C',
          }}
        >
          <span style={{ color: '#44484C', fontWeight: 600 }}>Patient Response: </span> {value}
        </Grid>
      </Grid>
    );
  }
  return null;
};

export default SecureLinkClinicalResponse;
