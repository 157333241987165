/* eslint-disable no-shadow */
import { Socket } from 'socket.io-client';
import { IFaGrantNotification } from './IFaGrantNotification';

export enum EventEmitsSocket {
  roomAccessesCustomer = 'RoomAccessesCustomer',
  patientAccesses = 'PatientAccesses',
}

export enum EventListenersSocket {
  allOnlineClients = 'AllOnlineClients',
  notifyFaGrant = 'NotifyFaGrant',
  updatePhones = 'UpdatePhones',
  updateStatusFCSS = 'UpdateStatusFCSS',
  questionHide = 'QuestionHide',
  questionShow = 'QuestionShow',
  newTask = 'NewTask',
}

export interface ISocket {
  faSocket: Socket | null;
  fcSocket: Socket | null;
  rulesEngineSocket: Socket | null;
  notifications: IFaGrantNotification[];
  lastNotification: IFaGrantNotification | null;
  usersWorkingTasks: any[] | null;
}
