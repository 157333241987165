export enum UserPermissions {
  ApplicationManagerGtdEdit = 'application_manager_gtd_can_edit',
  ApplicationManagerCtdEdit = 'application_manager_ctd_can_edit',
  ApplicationManagerUsersManageUserLevel = 'application_manager_users_manage_user_level',
  ApplicationManagerUsersManageCustomerLevel = 'application_manager_users_manage_customer_level',
  ApplicationManagerArEdit = 'application_manager_ar_can_edit',
  ApplicationManagerTtcEdit = 'application_manager_ttc_can_edit',
  ApplicationManagerCpmpEdit = 'application_manager_cpmp_can_edit',
  ApplicationManagerCpmpPayorEdit = 'application_manager_cpmp_payor_can_edit',
  ApplicationManagerCpmpCipEdit = 'application_manager_cpmp_cip_can_edit',
  RsTasksManagement = 'rs_tasks_management',
  AccreditationCreator = 'accreditation_create',
  AccreditationApprover = 'accreditation_approve_reject',
  ApplicationManagerCpmpRsProtocolEdit = 'application_manager_cpmp_rs_protocol_can_edit',
  SmsBatch = 'sms_batch',
  ApplicationManagerRulesEngineEdit = 'application_manager_rules_engine_edit',
  ApplicationManagerCpmpSupportiveCarePlanAddEdit = 'application_manager_cpmp_supportive_care_plan_can_edit',
}

export enum CustomerFeatures {
  CdmCustomer = 'cdm_customer',
  MedSync = 'med_sync',
}
