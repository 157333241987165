import { createTheme, adaptV4Theme } from '@mui/material/styles';
import typography from './typography.scss';
import colors from './colors.module.scss';

const {
  main,
  pageHeader,
  cuttySark,
  faintBorder,
  formLabel,
  formItem,
  background,
  white,
  black,
  steelGrey,
  summaryPanel,
  offWhite,
  contrastText,
  hover,
  success,
  positive,
  warning,
  error,
  important,
  addBtnColor,
  taskTypeLabelOverdue,
  taskTypeLabelComplete,
  taskTypeLabelIncomplete,
  bluesky,
  lightGrey,
  trellisBlue,
  trellisLightBlue,
  teal,
  bluesky2,
  patina,
  greyBlue,
  valenciaRed,
  darkRed,
  grey2,
  grey3,
  deepBlue,
  deepBlue2,
  patinaWhite,
  patinaWhite4,
  redWhite,
  lightRed,
  lightGreen,
  yellow,
  yellow2,
  yellow3,
  grey12,
  grey13,
  grey14,
  grey23,
  grey25,
  greyLightBlue2,
  pearlGrey,
  adherenceIntHistoryTitleGrey,
  adherenceIntHistoryBorderGrey,
  timberWolf,
  silverMist,
  offWhite2,
  flaggedRed,
  greySubRow,
  orange,
  bismark,
  rollingStone,
  cornflowerblue,
  deepChestnutRed,
} = colors;

const { useNextVariants, fontFamily, fontWeightRegular } = typography;

export const defaultTheme = {
  overrides: {},
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    error: {
      main: error,
    },
    primary: {
      addBtnColor,
      background,
      black,
      contrastText,
      cuttySark,
      darkRed,
      deepBlue,
      deepBlue2,
      disabled: 'rgba(0, 0, 0, 0.34)',
      error,
      faintBorder,
      flaggedRed,
      formItem,
      formLabel,
      grey12,
      grey13,
      grey14,
      grey2,
      grey23,
      grey25,
      pearlGrey,
      adherenceIntHistoryTitleGrey,
      adherenceIntHistoryBorderGrey,
      timberWolf,
      silverMist,
      grey3,
      greyBlue,
      greyLightBlue2,
      hover,
      important,
      lightGreen,
      lightGrey,
      lightRed,
      main,
      offWhite,
      offWhite2,
      orange,
      pageHeader,
      patina,
      patinaWhite,
      patinaWhite4,
      positive,
      redWhite,
      steelGrey,
      success,
      summaryPanel,
      teal,
      trellisBlue,
      trellisLightBlue,
      valenciaRed,
      warning,
      white,
      yellow,
      yellow2,
      yellow3,
      deepChestnutRed,
      task: {
        checked: teal,
        borderColor: bluesky2,
        borderHighlightColor: patina,
        borderNewTaskColor: valenciaRed,
        backgroundColor: greyBlue,
        focusColor: 'rgba(0, 0, 0, 0.07)',
        number: grey12,
      },
      taskTypeLabelOverdue,
      taskTypeLabelComplete,
      taskTypeLabelIncomplete,
      bluesky,
      archive: {
        background: grey2,
      },
      headerCardBackground: {
        main: grey3,
      },
      greySubRow,
      bismark,
      rollingStone,
      cornflowerblue,
    },
    secondary: { main: white },
    text: {
      primary: steelGrey,
      secondary: grey12,
    },
    navigation: { main: deepBlue, secondary: cuttySark },
    verification: {
      verifyContainer: patinaWhite,
      nonVerifyContainer: redWhite,
      verifyWording: patina,
      nonVerifyWording: darkRed,
      verificationBtn: steelGrey,
      emrVerifiedContainer: yellow3,
      emrVerifiedIcon: yellow,
    },
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    map: {
      therapyAdministration: {
        'Pre-Therapy': success,
        'On Therapy': success,
        'On Hold': warning,
        'Discontinued': warning,
        'No-Go': error,
      },
      therapyEnrollment: {
        'Undecided': warning,
        'Opt in': success,
        'Opt out': error,
      },
      clinicStatus: {
        active: warning,
        inactive: success,
        discharged: grey2,
      },
    },
  },
  typography: {
    useNextVariants,
    fontFamily,
    fontWeightRegular,
    headline: {
      color: cuttySark,
      fontWeight: 400,
    },
    title: {
      color: deepBlue,
      fontWeight: 400,
    },
    subheading: {
      color: grey12,
    },
    fontSize: 13, // material ui default font size
  },
  font: {
    extraSmallFontSize: 10,
    smallFontSize: 12,
    mediumFontSize: 14,
    largeFontSize: 16,
    extraLargeFontSize: 20,
    formItemSize: '1rem',
    formLabelSize: 12,
    weight: {
      regular: 300,
      medium: 400,
      thick: 600,
    },
  },
  layout: {
    topBarHeight: 64,
    taskBarWidth: 54,
    taskListWidth: 500,
    taskFormLabelMinHeight: 27,
  },
  drawer: {
    zIndex: 1300,
  },
};

export default createTheme(adaptV4Theme(defaultTheme));
export { colors };
