import moment from 'moment';
import { IPatientProblem, ICdmCondition } from 'interfaces/redux/IPatient';
import { DATABASE_DATE_FORMAT } from 'constants/index';
import { ITherapy } from 'interfaces/redux/ITherapy';
import { ClinicalDataItem } from 'interfaces/clinical-data/ClinicalDataResponse';

import { IUpdateClinicalSuportRequest } from 'models/cdm/IUpdateClinicalSupportRequest';
import { IUpdateClinicalSuportResponse } from 'models/cdm/IUpdateClinicalSupportResponse';

import { CdmProgramClient } from 'clients/cdm-program';
import { PatientTherapiesClient } from 'clients/patient-therapies';
import { ClinicalSupportStatusValue } from 'constants/enums';
import { convertToArborDate, dateFormat, datetimeFormat } from 'models/time/arbor-date';
import {
  IFormFields,
  ProblemStatus,
  IRiskStratLabel,
  IFormGoal,
  IGoalActions,
  IUpdateEnrollmentInput,
} from './interfaces';

export const formToPatientProblem = (formValues: IFormFields): Partial<IPatientProblem> => {
  return {
    diagnosis: formValues.diagnosis,
    onset_date: formValues.onsetDate ? formValues.onsetDate.format(DATABASE_DATE_FORMAT) : null,
    notes: formValues.notes ? formValues.notes : null,
    active: formValues.status === ProblemStatus.Active,
  };
};

export const patientProblemToFormFields = (problem: IPatientProblem): Partial<IFormFields> => {
  return {
    diagnosis: problem.diagnosis,
    diagnosisCode: problem.diagnosis.code,
    notes: problem?.notes ? problem.notes : '',
    onsetDate: problem.onset_date ? moment(problem.onset_date) : null,
    status: problem.active ? ProblemStatus.Active : ProblemStatus.Inactive,
  };
};

export const buildInitialRowStatusForProblemsList = (
  problems: IPatientProblem[] | undefined,
): Record<number, boolean> => {
  return (problems || []).reduce(
    (accum, problem) => ({
      ...accum,
      [problem.id]: false,
    }),
    {} as Record<number, boolean>,
  );
};

export const shouldRenderCdmInformation = (problem?: IPatientProblem | null): boolean => {
  return Boolean(problem && problem?.therapies?.length && problem?.cdm_program_id);
};

export const parseClinicalDataName = (
  name: string,
): { name: string; unit: string | null } | null => {
  const firstInParentheses = name?.match(/\(([^)]+)\)/);

  // given the double equals this is the same than === null || === undefined
  if (name == null) {
    return null;
  }

  if (firstInParentheses == null) {
    return {
      name: name,
      unit: null,
    };
  }

  const inParenthesesText = firstInParentheses[1];
  const nameOnly = name.replace(` (${inParenthesesText})`, '');

  return {
    name: nameOnly,
    unit: firstInParentheses[1],
  };
};

export const buildRiskStratLabels = (
  clinicalData: ClinicalDataItem[],
  problem?: IPatientProblem | null,
): IRiskStratLabel[] => {
  const sortedByAssessmentClinicalData = clinicalData.find(item => item.assessmentDate != null)
    ? clinicalData
        .filter(item => item.assessmentDate != null)
        .sort((itema, itemb) => moment(itemb.assessmentDate).diff(moment(itema.assessmentDate)))
        .sort((a, b) => {
          if (a.assessmentDate === b.assessmentDate) {
            return b.id - a.id;
          }
          return 1;
        })
    : clinicalData
        .sort((a, b) => a.clinicalDataTypeId - b.clinicalDataTypeId)
        .sort((itema, itemb) => {
          if (itema.clinicalDataTypeId === itemb.clinicalDataTypeId) {
            return itemb.id - itema.id;
          }
          return 1;
        });
  return (problem?.cdm_encounter?.graduation?.conditions || []).reduce(
    (accum: IRiskStratLabel[], condition: ICdmCondition) => {
      const clinicalDataItem = sortedByAssessmentClinicalData.find(
        item => item.clinicalDataTypeId === condition.clinical_data_type.id,
      );

      const parsedClinicalDataName = parseClinicalDataName(condition.clinical_data_type.name);

      return clinicalDataItem
        ? [
            ...accum,
            {
              labItemName: `${parsedClinicalDataName?.name ?? ''}`,
              lastDataCollect: {
                date: moment(clinicalDataItem?.assessmentDate)?.format('YYYY/MM/DD') || '-',
                value: `${clinicalDataItem?.value || '-'} ${parsedClinicalDataName?.unit || ''}`,
              },
              patientGoalValue: `${condition.clinical_data_value}`,
            },
          ]
        : accum;
    },
    [],
  );
};

export const buildGoalsActions = (
  goalsByLabNameMap: Record<string, ICdmCondition>,
  formGoals?: IFormGoal[],
) => {
  return (formGoals || []).reduce(
    (accum: IGoalActions, formGoal: IFormGoal) => {
      const { create, update } = accum;

      const goal = goalsByLabNameMap[formGoal.labName];

      if (goal.patient_goal_id) {
        update.push({
          id: goal.patient_goal_id,
          clinicalDataType: goal.clinical_data_type.id,
          graduationValue: formGoal.labValue,
        });
      } else {
        create.push({
          clinicalDataType: goal.clinical_data_type.id,
          graduationValue: formGoal.labValue,
        });
      }

      return {
        create,
        update,
      };
    },
    {
      create: [],
      update: [],
    },
  );
};

export const execGoalActions = (
  patientId: number,
  cdmProgramId: number,
  goalActions: IGoalActions,
) => {
  const actions = [];

  if (goalActions.create.length) {
    actions.push(CdmProgramClient.createPatientGoals(patientId, cdmProgramId, goalActions.create));
  }
  if (goalActions.update.length) {
    actions.push(CdmProgramClient.updatePatientGoals(patientId, cdmProgramId, goalActions.update));
  }

  return Promise.all(actions);
};

export const buildClinicalSupportRequest = (
  params: IUpdateEnrollmentInput,
): IUpdateClinicalSuportRequest => {
  let clinicalSupportStatusReason: string | null = null;
  const clinicalSupportStatusId =
    params.formValues.undecidedReason === ClinicalSupportStatusValue.UndecidedDeferredDecision
      ? ClinicalSupportStatusValue.UndecidedDeferredDecision
      : params.formValues.clinicalSupport;
  if (clinicalSupportStatusId === ClinicalSupportStatusValue.OptIn) {
    clinicalSupportStatusReason = params.formValues.optInReason?.join(',') ?? null;
  } else if (clinicalSupportStatusId === ClinicalSupportStatusValue.OptOut) {
    clinicalSupportStatusReason = params.formValues.optOutReason?.join(',') ?? null;
  }
  const enrollmentDt = getDateTimeFromEnrollmentOrGraduationDateInput(
    params?.formValues?.enrollmentDate,
  );
  const graduationDt = getDateTimeFromEnrollmentOrGraduationDateInput(
    params?.formValues?.graduationDate,
  );
  return {
    patient_id: params.patientId,
    therapy_ids: params.therapyIds,
    problem_id: params.problemId,
    cdm_program_id: params.cdmProgramId,
    cdm_encounter_id: params.cdmEncounterId,
    clinical_support_status_id:
      clinicalSupportStatusId || ClinicalSupportStatusValue.UndecidedNotYetOffered,
    clinical_support_status_reason: clinicalSupportStatusReason,
    clinical_support_additional_reason: params.formValues.otherOptOutReason || null,
    graduation_dt: graduationDt,
    outreach_followup_date: params.formValues.followUpdate
      ? params.formValues.followUpdate.format(DATABASE_DATE_FORMAT)
      : null,
    enrollment_dt: enrollmentDt,
  };
};

export const updateProblemEnrollment = async (
  params: IUpdateEnrollmentInput,
): Promise<IUpdateClinicalSuportResponse> => {
  const request = buildClinicalSupportRequest(params);
  const response = await PatientTherapiesClient.batchUpdateClinicalSupport(
    params.patientId,
    request,
  );
  return response;
};

export const decideUndecidedActualStatus = (
  clinicalSupportStatus?: ClinicalSupportStatusValue | null,
): ClinicalSupportStatusValue | null => {
  if (!clinicalSupportStatus) {
    return null;
  }
  if (clinicalSupportStatus === ClinicalSupportStatusValue.UndecidedDeferredDecision) {
    return ClinicalSupportStatusValue.UndecidedNotYetOffered;
  }
  return clinicalSupportStatus;
};

const getDateTimeFromEnrollmentOrGraduationDateInput = (
  dateInput: moment.Moment | undefined | null,
): string | null => {
  // The challenge here is that sometimes we are using a datetime and sometimes just a date with no time component for these inputs.
  // The solution is to always store the datetime. This method converts a date input to a datetime.
  if (dateInput == null) {
    return null;
  }
  // If the input is today, use the current time.
  const rightNow = moment.utc();
  const isDateInputToday = rightNow.isSame(dateInput, 'day');
  if (isDateInputToday) {
    return rightNow.format(datetimeFormat);
  }

  // Otherwise, use the day portion of the input.
  // Extract the day from date input.
  const dateInputDateString = dateInput.format(dateFormat);
  // Construct the Arbor date and format.
  const arborDate = convertToArborDate(dateInputDateString, false);
  return arborDate.getUtcDate(false, datetimeFormat);
};
