import React from 'react';
import RenderTableSelect from 'components/form/field/table-select';
import useProvider from 'hooks/useProvider';

export default ({ providers, field, label, input, meta, disabled, labelSize, qaId }) => {
  const table = useProvider(providers, field.provider, {
    headers: [],
    data: [],
  });

  return (
    <RenderTableSelect
      label={label}
      input={input}
      meta={meta}
      disabled={disabled}
      labelSize={labelSize}
      id={qaId}
      headers={table.headers}
      data={table.data}
      group={table.group}
      multi={field.component === 'tableSelectMultiple'}
      disableList={table.disableList || []}
    />
  );
};
