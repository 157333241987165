import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { styles } from './field-styles';
import { ReactSelectControlled } from './react-select';

function stopPropagation(e) {
  e.stopPropagation(); // prevent therapy summary panel expanding
}

const Dropdown = ({
  label,
  state,
  handler,
  fields,
  name,
  classes,
  dataQaId,
  id,
  enabled,
  useNumericValue,
  minimal,
  ...custom
}) => {
  const { width, isClearable, clearValue } = custom;
  const containerWidth = width || '100%';
  if (!state) return null;
  if (fields && fields.length && typeof fields[0] === 'string') {
    fields = fields.map(it => ({ label: it, value: it }));
  } else if (fields && fields.length && typeof fields[0] === 'number' && useNumericValue) {
    fields = fields.map(it => ({ label: `${it}`, value: it }));
  } else {
    fields = fields.map(it => ({
      label: it.value,
      value: it.key,
      disabled: it.disabled,
    }));
  }
  const selectedValue = (fields && fields.find(it => it.value === state)) || state;
  const selectStyles = {
    menu: base => ({
      ...base,
      zIndex: 10,
    }),
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: containerWidth,
      }}
    >
      <ReactSelectControlled
        className={classes.statusDropdown}
        styles={selectStyles}
        label={label}
        fields={fields}
        value={selectedValue}
        onChange={selected =>
          selected?.value
            ? handler({ target: { value: selected.value } })
            : handler({ target: { value: clearValue } })
        }
        onClick={stopPropagation}
        dataQaId={dataQaId}
        id={id}
        enabled={enabled}
        isClearable={isClearable}
        minimal={minimal}
      />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Dropdown);
