// @ts-nocheck
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import React, { useEffect, useState } from 'react';
import { archiveInfo, assignRankingAndKeys } from 'services/utils/demographic-service';
import { AxiosPromise } from 'axios';
import { buildQaId } from 'utils/build-qa-id';
import { change, FieldArray, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { editPatient, updatePatientPreferences } from 'actions/action-patient';
import { getModalStyle } from 'services/utils/styles-service';
import { Grid, Modal, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { IState } from 'interfaces/redux/IState';
import { nameOfFactory } from 'utils/types-util';
import { RenderAddressesForContacts } from 'components/form/subform/subform';
import { connect, useDispatch, useSelector } from 'react-redux';
import { editContactAndUpdatePatient } from 'actions/action-contacts';
import { styles } from '../add-address-modal/add-address-modal.styles';
import { IProps } from './interfaces/IProps';
import { IFormProps } from './interfaces/IFormProps';

const PATIENT_DELIVERY_CONTACT_ID = 0;

const UNIQUE_CONTACT_TYPE_ID_FOR_PATIENT_KEY = 'patient';
const UNIQUE_CONTACT_TYPE_ID_FOR_CONTACT_KEY = 'contact';

const FORM_ID = 'edit-contact-address-modal';

// #region interfaces for the component
interface IEditContactAdressesModalProps extends IProps, InjectedFormProps<IFormProps> {}
interface IStateProps {
  initialValues: Partial<IFormProps>;
}

type Props = IStateProps &
  IEditContactAdressesModalProps & {
    visible: boolean;
    onModalClose: () => void;
  };
// #endregion

// #region constant things
const qaIdBuilder = buildQaId(FORM_ID);
const nameOfFormFields = nameOfFactory<IFormProps>();

// #endregion

const EditContactAddressModal: React.FC<Props> = (props: any): JSX.Element => {
  const { classes, formValues } = props;

  // #region component state
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [saveDisabled, setSaveDisabled] = React.useState<boolean>(false);
  // #endregion

  // #region redux
  const patient = useSelector((state: IState) => state.patient);
  const dispatch = useDispatch();
  // #endregion

  // #region functions
  const handleCancel = (): void => {
    setErrorMessage('');
    setSaveDisabled(false);
    props.onModalClose();
    props.reset();
  };

  const handleSubmit = async (formValues: any) => {
    setSaveDisabled(true);

    const contactEditPayloads = [];
    const contactEditPromises = [];

    const patientAddresses = formValues.addresses
      .filter(({ ownerType }) => ownerType === UNIQUE_CONTACT_TYPE_ID_FOR_PATIENT_KEY)
      .map(({ ownerType, ownerId, ...address }) => address);

    const contactAddresses = formValues.addresses.filter(
      ({ ownerType }) => ownerType === UNIQUE_CONTACT_TYPE_ID_FOR_CONTACT_KEY,
    );

    const initialContactAddresses = props.initialValues.addresses?.filter(
      ({ ownerType }) => ownerType === UNIQUE_CONTACT_TYPE_ID_FOR_CONTACT_KEY,
    );

    const contactPayloads: Record<string, Array<any>> = {};

    contactAddresses.forEach(contactAddress => {
      let contactKeyId;

      const { ownerId, ownerType, ...newContactAddress } = contactAddress;

      const matches = ownerId.match(/(\d+)$/);
      if (matches) {
        contactKeyId = Number(matches[1]);
      }

      if (contactKeyId) {
        if (!contactPayloads[contactKeyId]) {
          contactPayloads[contactKeyId] = [newContactAddress];
        } else {
          contactPayloads[contactKeyId].push(newContactAddress);
        }
      }
    });

    Object.entries(contactPayloads).forEach(([contactId, contactAddressesAux]) => {
      const foundInitFormContactAddresses = initialContactAddresses
        .filter(contactInfo => {
          const matches = contactInfo.ownerId.match(/(\d+)$/);
          if (matches) {
            const contactKeyId = Number(matches[1]);
            return contactKeyId === Number(contactId);
          }
        })
        .reduce((acc, addressInfo) => {
          const { ownerId, ownerType, ...restAddressInfo } = addressInfo;
          return [...acc, restAddressInfo];
        }, []);

      const contactEditPayload = {
        id: contactId,
        patient_id: props.patient.id,
        addresses: JSON.stringify(
          assignRankingAndKeys(
            archiveInfo(contactAddressesAux, foundInitFormContactAddresses || []),
            null,
          ),
        ),
      };

      contactEditPayloads.push(contactEditPayload);
      contactEditPromises.push(
        dispatch(editContactAndUpdatePatient(contactEditPayload) as unknown) as AxiosPromise<any>,
      );
    });

    const patientPreferencesEditPayload = {
      patient_id: patient.id,
      is_declined_to_provide_addresses: formValues.is_declined_to_provide_addresses,
      preferred_rx_delivery_patient:
        !formValues.preferred_rx_delivery_contact_id ||
        formValues.preferred_rx_delivery_contact_id === PATIENT_DELIVERY_CONTACT_ID,
      // ID of the contact, or null if its patient
      preferred_rx_delivery_contact_id:
        formValues.preferred_rx_delivery_contact_id === PATIENT_DELIVERY_CONTACT_ID
          ? null
          : formValues.preferred_rx_delivery_contact_id,
      // Index of the contacts in the list to show, patient address index or contact address index
      preferred_rx_delivery_entity_key: formValues.preferred_rx_delivery_entity_key,
      preferred_rx_delivery_method: formValues.preferred_rx_delivery_method,
    };

    const patientEditPayload = {
      id: props.patient.id,
    };

    if (patientAddresses && patientAddresses.length > 0) {
      patientEditPayload.addresses =
        patientAddresses && patientAddresses.length > 0
          ? JSON.stringify(
              assignRankingAndKeys(archiveInfo(patientAddresses as [], patient.addresses), null),
            )
          : null;
    }

    if (patientAddresses && patientAddresses.length === 0 && patient.addresses?.length > 0) {
      // Do we need to remove patient address when they are reassign to a contact?
      patientEditPayload.addresses = JSON.stringify(
        assignRankingAndKeys(archiveInfo([], patient.addresses), null),
      );
    }

    Promise.all([
      dispatch(
        editPatient({ ...patientEditPayload, ...patientPreferencesEditPayload }),
      ) as unknown as Promise<object>,
      ...contactEditPromises,
    ])
      .then(() => {
        handleCancel();
      })
      .catch(error => {
        setSaveDisabled(false);
        setErrorMessage('Could not update address information');
      });
  };

  // #endregion

  return (
    <Modal open={props.visible} data-qa-id={qaIdBuilder('modal')}>
      <form data-qa-id={qaIdBuilder(FORM_ID)} autoComplete="off">
        <div
          style={{ ...getModalStyle(), maxHeight: '50rem', overflowY: 'scroll', width: '900px' }}
          className={classes.addModal}
        >
          <Grid container>
            {errorMessage && (
              <Grid item xs={12} className={classes.row}>
                <Typography className={classes.error}>{errorMessage}</Typography>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} className={classes.row}>
            <Typography variant="h6" className={classes.heading}>
              Edit Address
            </Typography>
          </Grid>

          <FieldArray
            name="addresses"
            formValues={formValues}
            preferredField="preferred_address_index"
            declineField="is_declined_to_provide_addresses"
            component={RenderAddressesForContacts}
            source="patient"
            change={(fieldName: string, fieldValue: any) => {
              dispatch(change(FORM_ID, fieldName, fieldValue));
            }}
            classes={classes}
            idPrefix="patient_form"
            patient={patient}
            numberOfPotentialRxDeliveryAddresses={0}
          />

          <Grid item xs={12}>
            <ConfirmationPanel
              handleSubmit={props.handleSubmit(handleSubmit)}
              handleCancel={handleCancel}
              disableSubmit={saveDisabled}
            />
          </Grid>
        </div>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state: any): any => {
  const { patient, audit, contactList: contacts } = state;

  let preferredAddressIndex = 0;

  const stripDeleted = ({ deleted }: { deleted?: boolean }) => deleted === undefined || !deleted;

  const initialValues = {
    addresses: [
      ...(patient.addresses?.filter(stripDeleted).map((address: any) => ({
        ownerType: UNIQUE_CONTACT_TYPE_ID_FOR_PATIENT_KEY,
        ownerId: `${UNIQUE_CONTACT_TYPE_ID_FOR_PATIENT_KEY}_${patient.id}`,
        ...address,
      })) || [
        {
          ownerType: UNIQUE_CONTACT_TYPE_ID_FOR_PATIENT_KEY,
          ownerId: `${UNIQUE_CONTACT_TYPE_ID_FOR_PATIENT_KEY}_${patient.id}`,
        },
      ]),
      ...(Object.entries(contacts).reduce((acc, [contactId, contact]) => {
        // @ts-ignore
        if (contact.addresses?.filter(stripDeleted).length > 0) {
          // @ts-ignore
          const contactAddress =
            contact.addresses.filter(stripDeleted).map((address: any) => ({
              ownerType: UNIQUE_CONTACT_TYPE_ID_FOR_CONTACT_KEY,
              ownerId: `${UNIQUE_CONTACT_TYPE_ID_FOR_CONTACT_KEY}_${contactId}`,
              ...address,
            })) || [];
          return acc.concat(contactAddress);
        }
        return acc;
      }, []) || []),
    ],
    preferred_address_index: 0,
    is_declined_to_provide_addresses: 0,
    preferred_contact_method: patient.preferred_contact_method,
    preferred_rx_delivery_contact_id: patient.preferred_rx_delivery_contact_id,
    preferred_rx_delivery_entity_key: patient.preferred_rx_delivery_entity_key,
    preferred_rx_delivery_patient: patient.preferred_rx_delivery_patient,
  };

  if (patient.preferred_rx_delivery_entity_key !== undefined) {
    if (
      Number(patient.preferred_rx_delivery_entity_key) > 0 &&
      !!patient.preferred_rx_delivery_patient
    ) {
      preferredAddressIndex = Number(patient.preferred_rx_delivery_entity_key) - 1;
    } else {
      const foundContactAddressIndex = initialValues.addresses.findIndex(addressToCompare => {
        if (addressToCompare.ownerType === UNIQUE_CONTACT_TYPE_ID_FOR_CONTACT_KEY) {
          const matches = addressToCompare.ownerId.match(/(\d+)$/);
          return matches && Number(matches[1]) === Number(patient.preferred_rx_delivery_contact_id);
        }
      });

      preferredAddressIndex =
        foundContactAddressIndex + Number(patient.preferred_rx_delivery_entity_key) - 1;
    }

    if (preferredAddressIndex > 0) {
      initialValues.preferred_address_index = preferredAddressIndex;
    }
  }

  const formValues = getFormValues(FORM_ID)(state) || initialValues;

  return {
    form: FORM_ID,
    initialValues,
    patient,
    formValues,
    auditState: audit,
    enableReinitialize: true,
  };
};

export default compose<any, any>(
  withStyles(styles),
  connect(mapStateToProps),
  reduxForm({}),
)(EditContactAddressModal);
