import React, { useState } from 'react';
import { Grid } from '@mui/material';
import cx from 'classnames';
import Button from 'components/questionnaire/components/button';
import WhiteContainer from '../../components/white-container';

import {
  NewAllergies,
  SideEffects,
  MedicationChanges,
  MissedDoses,
  PatientQuestions,
  NewConditions,
  HospitalVisits,
  NewInfections,
} from '../questions/clinical_questions';
import PaymentMethod from '../questions/payment_method_question';
import IncludedTherapies from '../questions/included-therapies';
import PickUpOrDelivery from '../questions/pickup_or_delivery_question';
import AncillarySupplies from '../questions/ancillary_supplies_question';
import PUorDeliveryDetails from '../questions/pu_or_delivery_details_question';
import Header from '../../components/header';
import ProgressContainer from '../../components/progress-container';
import SubHeader from '../../components/sub-header';

const questionListFull = [
  <IncludedTherapies />,
  <AncillarySupplies coldChain name="ancillary_supplies" />,
  <NewAllergies specialtyRequired name="new_allergies" />,
  <SideEffects specialtyRequired />,
  <MedicationChanges specialtyRequired />,
  <MissedDoses specialtyRequired name="missed_doses" />,
  <NewConditions specialtyRequired />,
  <HospitalVisits specialtyRequired />,
  <NewInfections specialtyRequired />,
  <PatientQuestions specialtyRequired />,
  <PickUpOrDelivery skipCA name="pick_up_or_delivery" />,
  <PUorDeliveryDetails name="pu_or_delivery_details" skipCA />,
  <PaymentMethod name="payment_method" />,
];

const QuestionsPage = ({ submit, store, classes, skipToPage }) => {
  let questionList = questionListFull.filter(q => {
    const onlyAncillaries = store.onlyAncillaryTherapies;
    const containsCA = store.patientAnswers.therapies.some(
      therapy => therapy.included && therapy.clinic_administered,
    );
    const containsColdChain = store.patientAnswers.therapies.some(
      therapy => therapy.included && therapy.is_cold_chain,
    );
    const condition1 = !onlyAncillaries || (onlyAncillaries && !q.props.specialtyRequired);
    const condition2 = !containsCA || (containsCA && !q.props.skipCA);
    const condition3 = !q.props.coldChain || containsColdChain;
    if (condition1 && condition2 && condition3) {
      return true;
    }
    return false;
  });
  const [questionIndex, setQuestionIndex] = useState({ index: 0, subQ: 0 });
  const [patientAnswers, setPatientAnswers] = useState({ ...store.patientAnswers });
  const [returnToReviewPage, setReturnToReviewPage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  let sameRenderHack = patientAnswers;

  const saveAnswer = response => {
    if (!Array.isArray(response)) {
      response = [response];
    }
    const newPatientAnswers = { ...patientAnswers };
    response.forEach(item => {
      if (/_details$/.test(item.keyName)) {
        if (item.value) {
          if (!newPatientAnswers.clinical_responses) {
            newPatientAnswers.clinical_responses = { [item.keyName]: item.value };
          } else {
            newPatientAnswers.clinical_responses[item.keyName] = item.value;
          }
        } else {
          delete newPatientAnswers?.clinical_responses?.[item.keyName];
          if (Object.keys(newPatientAnswers?.clinical_responses || {}).length === 0) {
            delete newPatientAnswers.clinical_responses;
          }
        }
      } else {
        newPatientAnswers[item.keyName] = item.value;
      }
    });
    const allSpecialtyNonPRNTherapiesSelected = newPatientAnswers.therapies.every(
      item => item.included || !item.is_specialty || item.as_needed,
    );
    const allTherapiesSelected = newPatientAnswers.therapies.every(item => item.included);
    newPatientAnswers.patient_contact_required = !allSpecialtyNonPRNTherapiesSelected ? 1 : 0;
    newPatientAnswers.patient_requested_therapy_changes = !allTherapiesSelected ? 1 : 0;
    questionList = questionListFull.filter(q => {
      const onlyAncillaries = store.onlyAncillaryTherapies;
      const containsCA = store.patientAnswers.therapies.some(
        therapy => therapy.included && therapy.clinic_administered,
      );
      const containsColdChain = store.patientAnswers.therapies.some(
        therapy => therapy.included && therapy.is_cold_chain,
      );
      const condition1 = !onlyAncillaries || (onlyAncillaries && !q.props.specialtyRequired);
      const condition2 = !containsCA || (containsCA && !q.props.skipCA);
      const condition3 = !q.props.coldChain || containsColdChain;
      if (condition1 && condition2 && condition3) {
        return true;
      }
      return false;
    });
    sameRenderHack = newPatientAnswers;
    setPatientAnswers(newPatientAnswers);
  };

  const nextQuestion = (index, fullNext) => {
    const onlyAncillaries = store.onlyAncillaryTherapies;
    // sending a subQ value of -1 to a multi-page question signals the
    // question to cycle through all the questions when answering while
    // on the review page.
    const subQ = fullNext ? -1 : 0;
    const containsCA = store.patientAnswers.therapies.some(
      therapy => therapy.included && therapy.clinic_administered,
    );
    const containsColdChain = store.patientAnswers.therapies.some(
      therapy => therapy.included && therapy.is_cold_chain,
    );
    if (returnToReviewPage && !index && !containsCA && !sameRenderHack.pickup_or_delivery) {
      const questionNumber = questionList.findIndex(q => q.props.name === 'pick_up_or_delivery');
      index = questionNumber;
    } else if (
      returnToReviewPage &&
      !index &&
      containsColdChain &&
      !sameRenderHack.ancillary_supplies_page_visited
    ) {
      const questionNumber = questionList.findIndex(q => q.props.name === 'ancillary_supplies');
      index = questionNumber;
    } else if (returnToReviewPage && typeof index !== 'string') {
      setQuestionIndex({ index: -1, subQ });
      setReturnToReviewPage(false);
      return;
    } else if (typeof index === 'string') {
      const questionNumber = questionList.findIndex(q => q.props.name === index);
      index = questionNumber;
    }
    if (index !== undefined) {
      setQuestionIndex({ index, subQ });
    } else if (questionIndex.index < questionList.length - 1) {
      setQuestionIndex({ index: questionIndex.index + 1, subQ });
    } else {
      setQuestionIndex({ index: -1, subQ });
    }
  };

  const editQuestionAndReturnToReview = (index, subQ) => {
    setQuestionIndex({ index, subQ });
    setReturnToReviewPage(true);
  };

  const skipQuestion = () => {
    setQuestionIndex({ index: questionIndex.index + 1, subQ: 0 });
  };

  const renderCurrentQuestion = () => {
    // this returns one of the questions
    if (questionIndex.index >= 0) {
      const component = questionList[questionIndex.index];
      return React.cloneElement(component, {
        saveAnswer,
        isReview: false,
        answerOptions: store.answerOptions,
        currentAnswers: patientAnswers,
        isEdit: returnToReviewPage,
        skipQuestion,
        skipToPage,
        store,
        questionIndex,
        nextQuestion,
        classes,
      });
    }
    // if questionIndex is negative then the review page is rendered

    const localSubmit = () => {
      setIsSubmitting(true);
      submit(patientAnswers);
    };

    return (
      <>
        <Grid>
          <Grid container spacing={3.5}>
            {questionList.map((q, index) => {
              if (
                !store.onlyAncillaryTherapies ||
                (store.onlyAncillaryTherapies && !q.props.specialtyRequired)
              ) {
                return React.cloneElement(q, {
                  classes,
                  key: index,
                  isReview: true,
                  currentAnswers: patientAnswers,
                  answerOptions: store.answerOptions,
                  questionIndex,
                  onEdit: subQ => editQuestionAndReturnToReview(index, subQ),
                });
              }
              return null;
            })}
          </Grid>
        </Grid>
        <Button
          className={classes.confirmButton}
          disabled={isSubmitting}
          onClick={localSubmit}
          data-qa-id="confirm"
        >
          Confirm & Send
        </Button>
      </>
    );
  };

  return (
    <WhiteContainer>
      <Header title="It's time for your refill" />
      {questionIndex.index >= 0 && !returnToReviewPage ? (
        questionIndex.index > 0 ? (
          <ProgressContainer index={questionIndex.index} length={questionList.length - 1} />
        ) : null
      ) : (
        <SubHeader title={returnToReviewPage ? 'Edit response' : 'Please, review your responses'} />
      )}
      <div
        className={cx(
          questionIndex.index >= 0 && classes.questionContentContainer,
          classes.questionContent,
        )}
      >
        {renderCurrentQuestion()}
      </div>
    </WhiteContainer>
  );
};

export default QuestionsPage;
