const commonStyles = {
  padding: 3,
  borderRadius: 5,
  fontSize: 11,
  display: 'inline-block',
  textAlign: 'center',
  verticalAlign: 'middle',
  height: 12,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  marginRight: 3,
  marginBottom: 3,
  whiteSpace: 'pre-wrap',
};

const commonStylesPhone = {
  paddingTop: 3,
  paddingRight: 7,
  paddingLeft: 3,
  borderRadius: 10,
  fontSize: 11,
  display: 'inline-block',
  textAlign: 'center',
  verticalAlign: 'middle',
  height: 19,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  marginRight: 3,
  marginBottom: 3,
};

export const styles = theme => ({
  completeLabel: {
    ...commonStyles,
    backgroundColor: theme.palette.primary.taskTypeLabelComplete,
    color: theme.palette.primary.background,
  },
  onTimeLabel: {
    ...commonStyles,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.background,
  },
  overdueLabel: {
    ...commonStyles,
    backgroundColor: theme.palette.primary.taskTypeLabelOverdue,
    color: theme.palette.primary.background,
    whiteSpace: 'normal',
  },
  phoneLabel: {
    ...commonStylesPhone,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.background,
  },
  disabledPhoneLabel: {
    ...commonStylesPhone,
    backgroundColor: theme.palette.primary.trellisBlue,
    color: theme.palette.primary.background,
    opacity: 0.4,
  },
  warningLabel: {
    padding: 3,
    borderRadius: 5,
    verticalAlign: 'middle',
    fontSize: 13,
    textAlign: 'center',
    display: 'inline-block',
    backgroundColor: theme.palette.primary.warning,
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  warningLabelPointer: {
    padding: 3,
    borderRadius: 5,
    verticalAlign: 'middle',
    fontSize: 13,
    textAlign: 'center',
    display: 'inline-block',
    backgroundColor: theme.palette.primary.warning,
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  taskSidebarDescriptorLabel: {
    ...commonStyles,
    backgroundColor: theme.palette.primary.summaryPanel,
    color: theme.palette.primary.formItem,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.faintBorder,
  },
  urgent: {
    ...commonStyles,
    backgroundColor: theme.palette.primary.lightRed,
    color: theme.palette.primary.white,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.faintBorder,
  },
  pinnedLabel: {
    padding: 5,
    borderRadius: 5,
    verticalAlign: 'middle',
    fontSize: 12,
    backgroundColor: theme.palette.primary.redWhite,
    textAlign: 'center',
    display: 'inline-block',
    color: theme.palette.primary.darkRed,
  },
  clinicLabel: {
    padding: 3,
    borderRadius: 5,
    fontSize: 11,
    display: 'inline-block',
    verticalAlign: 'middle',
    backgroundColor: theme.palette.primary.background,
    textAlign: 'center',
  },
  specialPopulations: {
    padding: 3,
    borderRadius: 5,
    verticalAlign: 'middle',
    fontSize: theme.font.smallFontSize,
    textAlign: 'center',
    display: 'inline-block',
    backgroundColor: theme.palette.primary.greyLightBlue2,
    color: theme.palette.primary.steelGrey,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  incompleteLabel: {
    ...commonStyles,
    backgroundColor: theme.palette.primary.taskTypeLabelIncomplete,
    color: theme.palette.primary.background,
  },
  indentedLeft: {
    marginLeft: theme.spacing(3),
  },
  indentedLeftBorder: {
    marginLeft: theme.spacing(3),
    borderLeft: '1px solid',
    borderColor: theme.palette.primary.grey13,
  },
  expandingViewIcon: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    bottom: 0,
    fontSize: 20,
    width: 15,
    paddingRight: theme.spacing(1),
    transform: 'translate(0, 5px)',
  },
  expandingViewLabel: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 14,
    bottom: 0,
    paddingTop: theme.spacing(1),
  },
  labelPadding: {
    marginLeft: 2,
    marginBottom: 2,
  },
  abbreviatedListText: {
    marginLeft: 2,
    paddingRight: theme.spacing(1),
    marginBottom: 2,
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
  auditSpacing: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  warningAudit: {
    color: theme.palette.primary.lightRed,
  },
  auditTooltip: {
    fontSize: 9,
  },
  noMaxWidthStyle: {
    maxWidth: 140,
  },
});
