import React from 'react';
import { memoize } from 'lodash';
import { InterventionButton } from 'components/intervention-button/intervention-button';
import dcJson from 'config/task-types/dc.json';
import { getTreeMapFromDataTypes } from 'services/utils/data-collect';
import { THERAPY_CONTEXT, TASK_CONTEXT } from 'constants/index';
import {
  DispensingStatusValue,
  SpecialtyTherapiesWf,
  TherapyWorkflowLabel,
  TtcNames,
} from 'constants/enums';
import { windowFeatureIsEnabled } from 'config/window-features';
import { staticProviders, getCommonProviders } from './common-provider';
import { PatientTherapiesClient } from '../../../clients/patient-therapies';

const DisabledInterventionButton = () => <InterventionButton disabled />;

const therapyWorkflow = async therapy => {
  const response = await PatientTherapiesClient.getTherapyWorkflow(
    therapy.diagnosis_code,
    therapy.specialty_type ?? null,
  );
  const workflowLabel = TherapyWorkflowLabel[response.data?.therapyWorkflow] ?? '';
  return workflowLabel;
};

const memoizedTherapyWorkflow = memoize(therapyWorkflow);

export default (task, state) => {
  const getTherapyWorkflowResult = async () => {
    if (
      task?.therapy_id &&
      state.therapies[task.therapy_id] &&
      windowFeatureIsEnabled('task_firing_based_on_dispensing_status')
    ) {
      return memoizedTherapyWorkflow(state.therapies[task.therapy_id]);
    }
    return null;
  };

  return {
    ...staticProviders,
    ...getCommonProviders(task, state, dcJson),
    json: dcJson,
    initialValues: {
      canceled_dt: state.currentDateTime,
      completed_dt: state.currentDateTime,
      reviewed_dt: state.currentDateTime,
      data_collect_followup: !(
        task?.therapy_id &&
        state.therapies[task.therapy_id]?.dispensing_status_id === DispensingStatusValue.OptOut &&
        (async () => (await getTherapyWorkflowResult()) === SpecialtyTherapiesWf.SP)() &&
        state.therapies[task.therapy_id].trellis_therapeutic_class_name.toLowerCase() !==
          `${TtcNames.Oncology.toLowerCase()}`
      ),
      data_collect_view_toggle: 1,
      data_collected_date: state.currentDateTime,
    },
    dataCollectFields: getTreeMapFromDataTypes(state.clinicalDataTypes),
    dataCollectInformation: {
      id: task.id,
      status_id: task.status_id,
      patientClinicalDataId: task.patient_clinical_data_id,
      context: state.therapy ? THERAPY_CONTEXT : TASK_CONTEXT,
      therapy: task.therapy_id ? state.therapies[task.therapy_id] : null,
    },
    dataCollectViewOptions: [
      { id: 1, name: 'Data Collect' },
      { id: 2, name: 'Previous Data Collect' },
    ],
    daysFormatter: days => {
      if (!days) {
        return 'N/A';
      }
      return days % 7 === 0 ? `${days / 7} weeks` : `${days} days`;
    },
    customElements: [
      {
        id: 'disabled-intervention-button',
        content: DisabledInterventionButton,
      },
    ],
  };
};
