import React from 'react';
import {
  FormGroup,
  FormControl,
  FormLabel,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../../lib/themes/colors.module.scss';

const { grey3 } = colors;

const useStyles = makeStyles(theme => ({
  tableHeader: {
    backgroundColor: grey3,
  },
  container: {
    width: '100%',
  },
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const TableList = ({ input, headers, data, label, multi, group, disableList }) => {
  const value = input.value || (multi ? [] : null);
  const classes = useStyles();
  let groupedItems;
  if (group) {
    groupedItems = data.reduce((acc, dataItem) => {
      const { id, name } = group(dataItem);
      let existing = acc.find(it => it.id === id);
      if (!existing) {
        existing = {
          id,
          name,
          items: [],
        };
        acc.push(existing);
      }
      existing.items.push(dataItem);
      return acc;
    }, []);
  } else {
    groupedItems = [{ items: data }];
  }
  return (
    <FormControl component="fieldset" className={classes.container}>
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {groupedItems.map(g => {
          const allSelected =
            multi && g.items.filter(d => value.indexOf(d.id) > -1).length === g.items.length;
          return (
            <Box mb={3} mt={2}>
              <Table size="small">
                <TableHead className={classes.tableHeader}>
                  {g.name && (
                    <TableRow>
                      <TableCell colSpan={5} className={classes.title}>
                        {g.name}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    {multi && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={allSelected}
                          color="primary"
                          onChange={() => {
                            const selectedFromOtherGroups = value.filter(
                              id => !g.items.find(it => it.id === id),
                            );
                            input.onChange(
                              allSelected
                                ? selectedFromOtherGroups
                                : [
                                    ...selectedFromOtherGroups,
                                    ...g.items
                                      .filter(t => !disableList.includes(t.id))
                                      .map(d => d.id),
                                  ],
                            );
                          }}
                          value={false}
                        />
                      </TableCell>
                    )}
                    {headers.map(h => (
                      <TableCell index={h.id}>{h.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {g.items.map((d, index) => (
                    <TableRow index={d.id}>
                      {multi && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={disableList.includes(d.id) || value.some(it => it === d.id)}
                            color="primary"
                            onChange={() => {
                              if (value.indexOf(d.id) === -1) {
                                input.onChange([...value, d.id]);
                              } else {
                                input.onChange(value.filter(it => it !== d.id));
                              }
                            }}
                            value={d.id}
                            disabled={disableList.includes(d.id)}
                          />
                        </TableCell>
                      )}
                      {headers.map(h => (
                        <TableCell index={`row-${d.id || index}-${h.label}`}>
                          {h.getValue(d)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

export default TableList;
