import React, { useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { Grid, Modal, Popover } from '@mui/material';
import { BaseTable } from 'containers/application-manager/base-table/base-table';
import { IHeaderCell } from 'containers/application-manager/base-table/types';
import History from 'containers/common/history';
import withStyles from '@mui/styles/withStyles';
import { FieldTypes } from 'containers/application-manager/types';
import compose from 'recompose/compose';
import { IPopoverStateBase } from 'containers/application-manager/gtd/types';
import { FormField } from 'containers/application-manager/popover-field-forms/index';
import { PAGINATION_PARAMS } from 'containers/application-manager/ctd/constants';
import {
  editPbmInsurance,
  fetchPbmInsurances,
  editMedicalInsurance,
  fetchMedicalInsurances,
} from 'actions/action-financials';
import { getModalStyle } from 'services/utils/styles-service';
import { EditHistory, EditPencil } from 'components/icons/icons';
import { convertToArborDate } from 'models/time/arbor-date';
import { styles } from '../financials-styles';
import PharmacyBenefitsTableDetail from '../tables-details/pharmacy-benefit-table-detail';
import MedicalBenefitTableDetail from '../tables-details/medical-benefit-table-detail';

const BENEFITS_TYPE_ORDER = ['Primary', 'Secondary', 'Tertiary', 'Quaternary'];
interface IBenefitsTableProps {
  editPbmInsurance: any;
  fetchPbmInsurances: any;
  editMedicalInsurance: any;
  fetchMedicalInsurances: any;

  type: 'pharmacyBenefit' | 'medicalBenefit';
  dataset: IResult[];
  classes: Record<string, string>;
  hideTitleHeader?: boolean;
  planSponsors: any[];
}

interface IResult {
  id: number;
  patient_id: number;
  insurance_type_id: number;
  is_no_insurance: number;
  pbm_name: string;
  plan_name: string;
  plan_sponsor: string;
  pbm_phone_number: string;
  pbm_patient_id: string;
  pbm_bin_number: string;
  pbm_rx_group_number: string;
  pbm_processor_control_number: string;
  policy_holder_sponsor: string;
  insurance_type_name: any;
  pbm_relation_code: string;
  start_date: string;
  end_date: any;
  lis_level: any;
  lis_effective_date: any;
  lis_termination_date: any;
  lis_medicare_plan_type: any;
  lis_medicare_beneficiary_id: any;
  lis_effective_date_mbi: any;
  lis_benefit_id: any;
  lis_formulary_id: any;
  lis_date_of_service: any;
  lis_contract_number: any;
  lis_medicare_part_d_code: any;
  lis_other_payor_id_count: any;
  lis_cms_low_income_cost_sharing: any;
  lis_transaction_response_status: any;
  lis_next_medicare_part_d_termination: any;
  lis_next_medicare_part_d_effective_start: any;
  member_id: string;
  group_number: string;
  created: string;
  created_by: number;
  updated: string;
  updated_by: number;
  is_verified: number;
  verified_dt: any;
  verified_by: any;
  insurance_type: string;
  verified_by_user: any;
  valid: number;
  updated_by_user: string;
  order: number;
  verified?: string;
  children: undefined[];
}

export interface IBenefitsTableResponse {
  totalCount: number;
  results: IResult[];
}

type IPopoverState = IPopoverStateBase<IResult, IResult>;

const initialPopoverState: IPopoverState = {
  open: false,
};

const BenefitsTable: React.FC<IBenefitsTableProps> = (props: IBenefitsTableProps): JSX.Element => {
  const dispatch = useDispatch();
  const { classes } = props;

  // #region component state
  const [tableData, setTableData] = React.useState<IBenefitsTableResponse | undefined>(undefined);
  const [lastReload, setLastReload] = React.useState<Date>(new Date());
  const [popoverState, setPopoverState] = React.useState<IPopoverState>({ open: false });
  const [editHistoryModalOpen, setEditHistoryModalOpen] = React.useState<boolean>(false);
  const [editItemMap, setEditItemMap] = React.useState<any>({});
  const [selectedItem, setSelectedItem] = React.useState<IResult>({} as IResult);
  // #endregion

  // #region helpers
  const openPopover = (
    element: HTMLElement,
    value: unknown,
    config: {
      parent?: IResult;
      child?: IResult;
      fieldType: FieldTypes;
      field: keyof IResult;
      forceAsParent?: boolean;
    },
  ): void => {
    if (config.parent != null && config.child != null) {
      throw new Error('Cannot select both a parent and child cell. Either one or the other');
    }

    setPopoverState({
      open: true,
      anchorElement: element,
      parent: config.parent,
      child: config.child,
      field: config.field,
      fieldType: config.fieldType,
      value: value,
      forceAsParent: config.forceAsParent,
    });
  };

  const getEditCellComponent = (parent: IResult, open: boolean) => {
    const { type } = props;
    return (
      <div className={classes.editCellContainer}>
        <EditHistory
          onClick={(event: Event) => {
            event.stopPropagation();
            setSelectedItem(parent);
            setEditHistoryModalOpen(true);
          }}
        />
        <EditPencil
          onClick={(event: Event) => {
            if (open) {
              event.stopPropagation();
            }
            setEditItemMap({ ...editItemMap, [`${type}${parent.id}`]: true });
          }}
        />
      </div>
    );
  };

  const isPharmacyBenefit = props.type === 'pharmacyBenefit';
  const handleEditClose = (insurance: IResult) =>
    setEditItemMap({ ...editItemMap, [`${props.type}${insurance.id}`]: false });

  const NAME_COLUMN: IHeaderCell<IResult, undefined> = {
    label: 'Name',
    parentKey: isPharmacyBenefit ? 'pbm_name' : 'plan_name',
    sortable: true,
    hideLeftPadding: true,
    childColSpan: ['pharmacyBenefit', 'medicalBenefit'].includes(props.type) ? 9 : 8,
    // eslint-disable-next-line react/no-unstable-nested-components
    childComponent: (insurance: IResult) => {
      return isPharmacyBenefit ? (
        <PharmacyBenefitsTableDetail
          pbmInsurance={insurance}
          editItemMap={editItemMap}
          handleEditClose={() => handleEditClose(insurance)}
        />
      ) : (
        <MedicalBenefitTableDetail
          medicalInsurance={insurance}
          patientId={insurance.patient_id}
          editItemMap={editItemMap}
          handleEditClose={() => handleEditClose(insurance)}
        />
      );
    },
    parentMaxWidth: '300px',
    parentDisplayInline: true,
  };

  const STATUS_COLUMN: IHeaderCell<IResult, undefined> = {
    label: 'Status',
    parentKey: 'valid',
    hideLeftPadding: true,
    hideChildCol: true,
    sortable: true,
    parentValueFormatter: (value: any, parent) => {
      return !value || convertToArborDate(parent.end_date, true).isBeforeToday()
        ? 'Inactive'
        : 'Active';
    },
    parentCellClick: (event: any, parent: any) => {
      openPopover(event.currentTarget, !!parent?.valid, {
        parent,
        field: 'valid',
        fieldType: FieldTypes.ActiveInactive,
      });
    },
  };

  const VERIFIED_COLUMN: IHeaderCell<IResult, undefined> = {
    label: 'Verified',
    parentKey: 'verified',
    hideLeftPadding: true,
    hideChildCol: true,
    sortable: true,
  };

  const EDIT_COLUMN: IHeaderCell<IResult, undefined> = {
    label: '',
    sortable: false,
    hideLeftPadding: true,
    hideChildCol: true,
    parentComponent: getEditCellComponent,
  };

  const PHARMACY_COLUMN_SETTINGS: IHeaderCell<IResult, undefined>[] = [
    NAME_COLUMN,
    {
      label: 'Type',
      parentKey: 'insurance_type',
      hideLeftPadding: true,
      sortable: true,
      hideChildCol: true,
    },
    {
      label: 'Bin Number',
      parentKey: 'pbm_bin_number',
      hideLeftPadding: true,
      sortable: true,
      hideChildCol: true,
    },
    {
      label: 'Processor Control Number',
      parentKey: 'pbm_processor_control_number',
      hideLeftPadding: true,
      sortable: true,
      hideChildCol: true,
    },
    {
      label: 'Rx Group Number',
      parentKey: 'pbm_rx_group_number',
      hideLeftPadding: true,
      sortable: true,
      hideChildCol: true,
    },
    {
      label: 'Patient ID',
      parentKey: 'pbm_patient_id',
      hideLeftPadding: true,
      sortable: true,
      hideChildCol: true,
    },
    STATUS_COLUMN,
    VERIFIED_COLUMN,
    EDIT_COLUMN,
  ];

  const MEDICAL_COLUMN_SETTINGS: IHeaderCell<IResult, undefined>[] = [
    NAME_COLUMN,
    {
      label: 'Type',
      parentKey: 'insurance_type',
      hideLeftPadding: true,
      sortable: true,
      hideChildCol: true,
    },
    {
      label: 'Plan Sponsor',
      parentKey: 'plan_sponsor',
      hideLeftPadding: true,
      sortable: true,
      hideChildCol: true,
      parentValueFormatter: (value: any) => {
        return props.planSponsors.find(o => o.id === value)?.type;
      },
    },
    {
      label: 'Employer',
      parentKey: 'policy_holder_sponsor',
      hideLeftPadding: true,
      sortable: true,
      hideChildCol: true,
    },
    {
      label: 'Member ID',
      parentKey: 'member_id',
      hideLeftPadding: true,
      sortable: true,
      hideChildCol: true,
    },
    {
      label: 'Group ID',
      parentKey: 'group_number',
      hideLeftPadding: true,
      sortable: true,
      hideChildCol: true,
    },
    STATUS_COLUMN,
    VERIFIED_COLUMN,
    EDIT_COLUMN,
  ];

  const mapDataToTableResult = (data: IResult): any => {
    const result = {
      ...data,
      children: [data],
    } as any;
    return result;
  };

  useEffect(() => {
    let data = props.dataset;
    if (['pharmacyBenefit', 'medicalBenefit'].includes(props.type)) {
      data = data.map(item => {
        return {
          ...item,
          verified: item.is_verified ? 'Yes' : 'No',
        };
      });
    }
    data.sort(
      (a, b) =>
        BENEFITS_TYPE_ORDER.indexOf(a.insurance_type) -
        BENEFITS_TYPE_ORDER.indexOf(b.insurance_type),
    );
    setTableData({ totalCount: data?.length, results: data?.map(mapDataToTableResult) });
    setLastReload(new Date());
  }, [props.dataset]);

  const getHistoryURL = (patientId: number, id: number) =>
    isPharmacyBenefit
      ? `/patients/${patientId}/financials/patient-pbm-insurances/${id}`
      : `/patients/${patientId}/financials/patient-medical-insurances/${id}`;

  const handlePopoverSubmit = (value: any) => {
    const {
      editPbmInsurance, //eslint-disable-line
      fetchPbmInsurances, //eslint-disable-line
      editMedicalInsurance, //eslint-disable-line
      fetchMedicalInsurances, //eslint-disable-line
    } = props;
    const { field, parent: insurance } = popoverState;
    const payload = {
      id: insurance?.id,
      patient_id: insurance?.patient_id,
      end_date: insurance?.end_date ? convertToArborDate(insurance.end_date).getUtcDate() : null,
      [field as string]: Number(value),
    } as any;

    if (field === 'valid') {
      payload.end_date = value ? null : convertToArborDate(moment()).getUtcDate();
    }

    if (field === 'is_verified' && value) {
      payload.verified_dt = convertToArborDate(moment()).getUtcDatetime();
    }

    if (isPharmacyBenefit) {
      editPbmInsurance(payload).then(() => {
        fetchPbmInsurances(insurance?.patient_id);
      });
    } else {
      editMedicalInsurance(payload).then(() => {
        fetchMedicalInsurances(insurance?.patient_id);
      });
    }

    setPopoverState(initialPopoverState);
  };
  // #endregion

  // #region renders
  const renderPopover = (): JSX.Element => {
    const handleCancel = () => setPopoverState(initialPopoverState);

    return (
      <Popover
        id="form"
        open={popoverState.open}
        anchorEl={popoverState?.anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transitionDuration={{ exit: 0 }}
        onClose={() => setPopoverState(initialPopoverState)}
      >
        <FormField
          initialValue={popoverState.value}
          handleCancel={handleCancel}
          handleSubmit={handlePopoverSubmit}
          fieldType={popoverState.fieldType}
        />
      </Popover>
    );
  };

  const renderHistoryModal = () => {
    return (
      <Modal open={editHistoryModalOpen} onClose={() => setEditHistoryModalOpen(false)}>
        <div style={getModalStyle()} className={classes.Modal}>
          <History url={getHistoryURL(selectedItem.patient_id, selectedItem.id)} />
        </div>
      </Modal>
    );
  };

  const { hideTitleHeader } = props;
  return (
    <>
      {renderPopover()}
      {renderHistoryModal()}
      <Grid container paddingX={hideTitleHeader ? 0 : 4} className={classes.benefitTableContainer}>
        {!hideTitleHeader && (
          <Grid item xs={12}>
            <h2 className={classes.benefitTableTitle}>
              {props.type === 'pharmacyBenefit' ? 'Pharmacy' : 'Medical'} Benefit
            </h2>
          </Grid>
        )}
        <Grid item xs={12}>
          <BaseTable<IBenefitsTableResponse, IResult, undefined>
            triggerReload={lastReload}
            actionsPermitted
            dataSet={tableData}
            paginationQueryParamSettings={PAGINATION_PARAMS}
            childPkSelector={() => ''}
            parentPkSelector={p => p.id}
            columnSettings={isPharmacyBenefit ? PHARMACY_COLUMN_SETTINGS : MEDICAL_COLUMN_SETTINGS}
            searchTerm=""
            qaId={props.type}
          />
        </Grid>
      </Grid>
    </>
  );
};

function mapStateToProps(state: any) {
  const { uploadedDocuments, lookups } = state;

  return {
    uploadedDocuments,
    planSponsors: lookups.planSponsors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      fetchPbmInsurances,
      editPbmInsurance,
      editMedicalInsurance,
      fetchMedicalInsurances,
    },
    dispatch,
  );
}

export default compose<IBenefitsTableProps, {}>(
  withStyles(styles as any),
  connect(mapStateToProps, mapDispatchToProps),
)(BenefitsTable);
