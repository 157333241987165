import React from 'react';
import { Grid } from '@mui/material';
import { Field } from 'redux-form';
import FieldLabel from 'components/form/field/field-label';
import { FillCoordinationStatus } from 'interfaces/enums/TaskStatuses/FillCoordinationStatus';
import { renderReactRadioGroup as RenderRadioGroup } from 'components/form/field/redux-field';
import { staticProviders } from 'components/dynamic-form/providers/common-provider';
import { definedBoolean, required } from 'components/form/validation/validation';
import { nameOfFactory } from 'utils/types-util';
import { concatWithDefinedValidator } from '../fc-util';
import ClinicalResponses from '../components/secure-link-clinical-response';
import { IFillCoordinationEditFormFields, EditComponentMode } from '../types';

const nameOfFormFields = nameOfFactory<IFillCoordinationEditFormFields>();
function renderFieldLabel(fieldName: string, labelSize: string): JSX.Element {
  return <FieldLabel fieldName={fieldName} labelSize={labelSize} />;
}
const getQaId = (fieldName: string): string => {
  return `fill-coordination-edit-form-${fieldName}`;
};

export const HospitalVisit = (props: any) => {
  const { fieldSettings, hiddenQuestions, classes, clinicalResponses, fc_details } = props;
  const requiredWhenComplete: any[] =
    props.selectedStatus === FillCoordinationStatus.Completed ? [required] : [];
  return fieldSettings[nameOfFormFields('hospital_visit')] &&
    !hiddenQuestions.includes('hospital_visit') ? (
    <>
      <Grid
        data-fc-mv-id="hospital_visit"
        container
        item
        xs={12}
        className={classes.yesNoQuestionContainer}
      >
        <Grid item xs={9} className={classes.yesNoQuestionText}>
          {renderFieldLabel(
            'Has the patient been hospitalized or gone to the emergency department since we last spoke? *',
            'medium',
          )}
        </Grid>
        <Grid
          item
          xs={3}
          className={classes.yesNoQuestionText}
          data-qa-id={getQaId(nameOfFormFields('hospital_visit'))}
        >
          <Field
            name={nameOfFormFields('hospital_visit')}
            component={RenderRadioGroup}
            radioMap={staticProviders.yesNo01.map(item => ({
              value: item.id,
              label: item.name,
              disabled: fc_details.hospitalVisitDisableNo && item.id === 0,
            }))}
            validate={
              fc_details.selectedStatus === FillCoordinationStatus.Completed &&
              concatWithDefinedValidator(
                !definedBoolean(fc_details.tasks[0].hospital_visit),
                requiredWhenComplete,
              )
            }
            disabled={fc_details.componentMode === EditComponentMode.Edit}
          />
        </Grid>
        <ClinicalResponses comments={clinicalResponses} key_name="hospital_visit" />
      </Grid>
    </>
  ) : null;
};
