import React, { useState } from 'react';
import { ListItem, List, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReviewItem from 'components/fc-self-service/components/review-item';
import Question from 'components/fc-self-service/components/question';
import ChangesNeededConfirmationPage from '../../changes-needed-confirmation-page';

enum QUESTION {
  main = 0,
  confirmation_1 = 1,
  confirmation_2 = 2,
}
const EmptyCircle = (
  <div
    style={{
      height: '16px',
      width: '16px',
      border: '2px solid green',
      borderRadius: '50%',
      marginRight: '3px',
      marginLeft: '2px',
      padding: '2px',
    }}
  />
);

const CHANGES_NEEDED_PAGE = 3;
const EMPTY_ORDER_FINAL_PAGE = 10;
const IncludedTherapies = (props: any) => {
  const { classes } = props;
  const { saveAnswer, isReview, currentAnswers, onEdit, answerOptions, skipToPage, nextQuestion } =
    props;
  const keyName = 'therapies';
  const questionText = 'Confirm the medications in the order';
  const questionTextReview = questionText;
  const therapies = answerOptions?.therapies;

  // This is a multi-page question and this value sets which
  // question is rendered.
  const [questionIndex, setQuestionIndex] = useState(QUESTION.main);
  const [therapiesState, setTherapiesState] = useState(therapies);

  const internalHandler = (val: any) => {
    if (val === 'Next') {
      const controlled_substance_count = currentAnswers.therapies.reduce(
        (acc: any, therapy: any) => {
          if (therapy.included && therapy.is_controlled) {
            acc += 1;
          }
          return acc;
        },
        0,
      );
      if (controlled_substance_count) {
        saveAnswer([
          { keyName, value: therapiesState },
          { keyName: 'signature_required', value: 1 },
        ]);
      } else {
        saveAnswer({ keyName, value: therapiesState });
      }
      const allTherapiesHaveBeenRemovedFromOrder = therapiesState.every(
        (item: any) => !item.included,
      );
      const anySpecialty_NonPrn_TherapyRemovedFromOrder = therapiesState.some(
        (item: any) => item.is_specialty && !item.as_needed && !item.included,
      );
      if (allTherapiesHaveBeenRemovedFromOrder) {
        setQuestionIndex(QUESTION.confirmation_2); // show the empty order confirmation page
      } else if (anySpecialty_NonPrn_TherapyRemovedFromOrder) {
        setQuestionIndex(QUESTION.confirmation_1); // show the call needed confirmation page
      } else {
        nextQuestion();
      }
    } else {
      const therapy = therapiesState[val];
      const currentValue = therapy.included;
      const newTherapiesState = [...therapiesState];
      newTherapiesState[val].included = !currentValue;
      setTherapiesState(newTherapiesState);
    }
  };

  const confirmationResponseHandler = (response: any) => {
    if (response === 1 && questionIndex === QUESTION.confirmation_1) {
      return skipToPage(CHANGES_NEEDED_PAGE);
    }
    if (response === 1 && questionIndex === QUESTION.confirmation_2) {
      return skipToPage(EMPTY_ORDER_FINAL_PAGE);
    }
    if (isReview) {
      onEdit();
    } else {
      setQuestionIndex(QUESTION.main);
    }
  };

  // Display the main question
  if (questionIndex === QUESTION.main && !isReview) {
    return (
      <Question
        questionText={questionText}
        keyName={keyName}
        therapy_list={therapiesState}
        response={internalHandler}
        buttons={{ button1Text: 'Next', button1Value: 'Next' }}
      />
    );
  }

  if (questionIndex === QUESTION.main && isReview) {
    return (
      <ReviewItem
        key={props.key}
        questionText={questionTextReview}
        currentAnswer=""
        onEdit={onEdit}
        keyName={keyName}
      >
        <List className={classes.medicationList} dense>
          {therapies.map((therapy: any, index: number) => (
            <ListItem disableGutters key={index} data-qa-id={`therapy-${index}`}>
              {therapy.included ? <CheckCircleIcon className={classes.icon} /> : EmptyCircle}
              <Typography className={classes.medicationList}>{therapy.name}</Typography>
            </ListItem>
          ))}
        </List>
      </ReviewItem>
    );
  }

  if (questionIndex === QUESTION.confirmation_1) {
    // Display the confirmation question
    return <ChangesNeededConfirmationPage responseHandler={confirmationResponseHandler} />;
  }

  if (questionIndex === QUESTION.confirmation_2) {
    // Display the confirmation question
    return (
      <ChangesNeededConfirmationPage
        responseHandler={confirmationResponseHandler}
        confirmationPageIndex={questionIndex}
      />
    );
  }
  return null;
};
export default IncludedTherapies;
