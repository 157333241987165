/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Button, Grid, Modal, styled, Typography, useTheme } from '@mui/material';
import { ArrowForwardIcon, RetryIcon } from 'components/icons/icons';
import React, { MouseEventHandler, useState, useEffect } from 'react';
import {
  patientDeceasedReconciliationSelector,
  patientNeedsReconciliationSelector,
  reconciliationSelector,
} from 'containers/patient/reconciliation/common';
import { useTypedSelector } from 'hooks/use-typed-selector';
import ReconciliationList from 'containers/patient/reconciliation/reconciliation-list';
import { IReconciliation } from 'interfaces/redux/IReconciliation';
import { getModalStyle } from 'services/utils/styles-service';
import Error from '@mui/icons-material/Error';

const getReconcileMessage = (
  reconciliation: IReconciliation,
) => `We've detected changes in this patient's information
from ${reconciliation.sourceNames.join(', ')}. Would you like to review the information and
reconcile it?`;

const Flex = styled(Box)(() => ({ display: 'flex', flexFlow: 'row', alignItems: 'center' }));

type UpdateButtonProps = React.PropsWithChildren<{
  onClick: MouseEventHandler;
  // eslint-disable-next-line react/require-default-props
  error?: boolean;
}>;
function UpdateButton({ children, onClick, error = false }: UpdateButtonProps) {
  const color = error ? 'primary.deepChestnutRed' : 'primary.trellisBlue';
  return (
    <Button
      onClick={onClick}
      sx={{
        color,
        lineHeight: 'normal',
        margin: '2px 0',
        display: 'flex',
        alignItems: 'baseline',
      }}
      endIcon={<ArrowForwardIcon size={20} color={color} />}
    >
      <span style={{ marginTop: '5px', whiteSpace: 'nowrap' }}>{children}</span>
    </Button>
  );
}

interface BannerProps {
  onOpenModal: MouseEventHandler;
}
function ReconciliationOrDeceasedBanner({ onOpenModal }: BannerProps) {
  const patientNeedsReconciliation = useTypedSelector(patientNeedsReconciliationSelector);
  const reconciliation = useTypedSelector(reconciliationSelector);
  const isPatientDeceased = useTypedSelector(patientDeceasedReconciliationSelector);
  const style = isPatientDeceased
    ? {
        backgroundColor: 'primary.redWhite',
        color: 'primary.deepChestnutRed',
      }
    : {
        backgroundColor: '#fff',
        color: 'primary.steelBlue',
        borderBottom: '5px solid',
        borderBottomColor: 'primary.warning',
      };

  return patientNeedsReconciliation ? (
    <Flex justifyContent="space-between" padding="4px 4px 4px 16px" sx={style}>
      {reconciliation.isLoading ? (
        <Grid item xs={12}>
          <Typography variant="subtitle2">Loading...</Typography>
        </Grid>
      ) : (
        <>
          <Flex sx={{ marginTop: '5px' }}>
            {isPatientDeceased ? <Error /> : <RetryIcon size={24} color="inherit" />}
            <Typography variant="subtitle2" sx={{ margin: '12px 8px' }}>
              {isPatientDeceased
                ? 'This patient is marked as Deceased in EMR'
                : getReconcileMessage(reconciliation)}
            </Typography>
          </Flex>
          <Flex>
            <UpdateButton onClick={onOpenModal} error={isPatientDeceased}>
              {isPatientDeceased ? 'Update Patient Information' : 'Reconcile Information'}
            </UpdateButton>
          </Flex>
        </>
      )}
    </Flex>
  ) : null;
}

export default function PatientReconciliationBanner() {
  const patientNeedsReconciliation = useTypedSelector(patientNeedsReconciliationSelector);

  const [modalOpen, setModal] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (!patientNeedsReconciliation) setModal(false);
    return () => setModal(false);
  }, [patientNeedsReconciliation]);

  return (
    <>
      <ReconciliationOrDeceasedBanner onOpenModal={() => setModal(true)} />
      <Modal open={modalOpen} onClose={() => setModal(false)}>
        <Box
          sx={{
            ...getModalStyle(),
            position: 'absolute',
            width: theme.spacing(150),
            height: '80%',
            backgroundColor: (theme.palette.primary as any).background,
            boxShadow: theme.shadows[5],
            borderRadius: 5,
            overflow: 'auto',
          }}
        >
          <ReconciliationList />
        </Box>
      </Modal>
    </>
  );
}
