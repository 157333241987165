import React from 'react';
import PickUpDates from './pickup_questions';
import DeliveryQuestions from './delivery_questions';

const PUorDeliveryDetails = (props: any) => {
  return props.currentAnswers.pickup_or_delivery === 'delivery' ? (
    <DeliveryQuestions {...props} />
  ) : (
    <PickUpDates {...props} />
  );
};

export default PUorDeliveryDetails;
