import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Loader } from 'components/loading-overlay/loading-overlay';
import { ReactSelect } from 'components/form/field/react-select';
import { getInvoicingSummary } from 'services/utils/invoicing-service';
import moment from 'moment';
import { RX_TYPES } from 'constants/index';
import DateTimePicker from 'components/form/datepicker/datetime-picker';
import { notifyError } from 'actions/action-notifications';
import { useDispatch } from 'react-redux';
import SummaryCategoryRow from './summary-category-row';
import InvoicingSummaryAdjustments from './invoicing-summary-adjustments';

import useStyles from './styles';

const allType = { value: '.*', label: 'All' };

const getPrevMonthMoment = num => moment().utc().subtract(num, 'month').startOf('month');

const InvoicingSummary = () => {
  const { invoicingSummaryStyledTableCell, invoicingStyledLoadContainer } = useStyles();
  const [summaryData, setSummaryData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [fromMonth, setFromMonth] = useState(getPrevMonthMoment(3));
  const [toMonth, setToMonth] = useState(getPrevMonthMoment(1));
  const [clinicTypes, setClinicTypes] = useState([allType]);
  const [pharmacyTypes, setPharmacyTypes] = useState([allType]);
  const [clinicFilters, setClinicFilters] = useState([...clinicTypes]);
  const [pharmacyFilters, setPharmacyFilters] = useState([...pharmacyTypes]);
  const [rxTypeFilters, setRxTypeFilters] = useState([RX_TYPES[0], RX_TYPES[2]]);
  const [specialtyTypeFilters, setSpecialtyTypeFilters] = useState([RX_TYPES[0], RX_TYPES[2]]);
  const [summaryInvoiceSettings, setSummaryInvoiceSettings] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoaded(false);
    const filters = {
      fromDate: fromMonth.format('yyyy-MM'),
      toDate: toMonth.format('yyyy-MM'),
      clinics: Array.isArray(clinicFilters) ? clinicFilters.map(f => f.value) : [],
      pharmacies: Array.isArray(pharmacyFilters) ? pharmacyFilters.map(f => f.value) : [],
      rxTypes: Array.isArray(rxTypeFilters) ? rxTypeFilters.map(f => f.value) : [],
      specialtyTypes: Array.isArray(specialtyTypeFilters)
        ? specialtyTypeFilters.map(f => f.value)
        : [],
    };
    getInvoicingSummary(filters)
      .then(({ data: { summary, pharmacies, clinics, settings } }) => {
        if (Array.isArray(summary) && summary.length > 0) {
          const clinicList = clinics.map(c => ({
            label: c,
            value: c,
          }));

          if (clinicList.length > 0) {
            setClinicTypes([allType, ...clinicList]);
          }
          const pharmacyList = pharmacies.map(c => ({
            label: c,
            value: c,
          }));
          if (pharmacies.length > 0) {
            setPharmacyTypes([allType, ...pharmacyList]);
          }
        }
        if (settings.length > 0) {
          setSummaryInvoiceSettings(settings);
        }
        setSummaryData(summary);
        setIsLoaded(true);
      })
      .catch(err => {
        dispatch(notifyError(err.message));
        setIsLoaded(true);
      });
  }, [fromMonth, toMonth, clinicFilters, pharmacyFilters, rxTypeFilters, specialtyTypeFilters]);

  const periodList = [];
  for (let m = moment(fromMonth); m.diff(toMonth) <= 0; m.add(1, 'month')) {
    periodList.unshift(m.format('MMMM'));
  }

  const renderPeriodColumns = classComponent => (
    <>
      <TableCell className={classComponent} align="center">
        <Typography variant="subtitle2">Value</Typography>
      </TableCell>
      <TableCell className={classComponent} align="center">
        <Typography variant="subtitle2">MoM</Typography>
      </TableCell>
      <TableCell className={classComponent} align="center">
        <Typography variant="subtitle2">MoM %</Typography>
      </TableCell>
    </>
  );

  return (
    <>
      <Box pt={2} />
      <Grid container>
        <Grid item xs={1}>
          <Typography variant="caption" component="div">
            From
          </Typography>
          <DateTimePicker
            id="from_month"
            showMonthYearPicker
            minDate={new Date(moment().subtract(1, 'year'))}
            maxDate={new Date(moment(toMonth.toDate()).add(1, 'month'))}
            value={fromMonth}
            onChange={e => setFromMonth(e)}
            dateFormat="yyyy/MM"
            popperPlacement="bottom-start"
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1}>
          <Typography variant="caption" component="div">
            To
          </Typography>
          <DateTimePicker
            id="to_month"
            showMonthYearPicker
            minDate={new Date(moment(fromMonth))}
            maxDate={new Date(moment().subtract(1, 'month'))}
            value={toMonth}
            onChange={e => setToMonth(e)}
            dateFormat="yyyy/MM"
          />
        </Grid>
      </Grid>
      <Box mt={2} />
      <Grid container>
        <Grid item lg={2}>
          <Typography variant="caption" component="div">
            Clinics
          </Typography>
          <ReactSelect
            value={clinicFilters}
            handleOnChange={e => setClinicFilters(e)}
            fields={clinicTypes}
            id="clinic_filter"
          />
        </Grid>
        <Grid item lg={2}>
          <Typography variant="caption" component="div">
            Pharmacies
          </Typography>
          <ReactSelect
            value={pharmacyFilters}
            handleOnChange={e => setPharmacyFilters(e)}
            fields={pharmacyTypes}
            id="pharmacy_filter"
          />
        </Grid>
        <Grid item lg={4} />
        <Grid item lg={2}>
          <Typography variant="caption" component="div">
            Rx Invoice Type
          </Typography>
          <ReactSelect
            value={rxTypeFilters}
            handleOnChange={e => setRxTypeFilters(e)}
            fields={[RX_TYPES[0], RX_TYPES[2]]}
            id="rx_invoice_clinics"
          />
        </Grid>
        <Grid item lg={2}>
          <Typography variant="caption" component="div">
            Specialty Type
          </Typography>
          <ReactSelect
            value={specialtyTypeFilters}
            handleOnChange={e => setSpecialtyTypeFilters(e)}
            fields={[...RX_TYPES]}
            id="specialty_type_filter"
          />
        </Grid>
      </Grid>
      <Box pt={2} />
      <Grid className={invoicingStyledLoadContainer}>
        <Loader loaded={isLoaded}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="invoicing summary table">
              <TableHead>
                <TableRow>
                  <TableCell className={invoicingSummaryStyledTableCell} />
                  {periodList.map(month => (
                    <TableCell
                      className={invoicingSummaryStyledTableCell}
                      align="center"
                      colspan="3"
                    >
                      <Typography variant="subtitle2">{month}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className={invoicingSummaryStyledTableCell} align="center">
                    <Typography variant="subtitle2">Category</Typography>
                  </TableCell>
                  {periodList.map(() => renderPeriodColumns(invoicingSummaryStyledTableCell))}
                </TableRow>
              </TableHead>
              <TableBody>
                {summaryData.map(category => (
                  <SummaryCategoryRow
                    invoiceSettings={summaryInvoiceSettings}
                    category={category}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Loader>
      </Grid>
      <Box pt={2} />
      <InvoicingSummaryAdjustments />
      <Box pt={2} />
    </>
  );
};

export default InvoicingSummary;
