import React, { useState } from 'react';
import { Grid, Button, Typography, TextField, Box } from '@mui/material';

const ClinicalQuestion = props => {
  const { classes, questionText, subQText, response, keyName, buttons, currentAnswer } = props;

  const [showTextResponse, setShowTextResponse] = useState(!!currentAnswer);
  const [text, setText] = useState(currentAnswer || '');
  const internalResponse = resp => {
    if (resp && subQText) {
      setShowTextResponse(true);
    } else {
      response({ value: resp, details: null });
    }
  };
  const limitTo255Characters = event => {
    const input = event.target.value;
    if (input.length <= 255) {
      setText(input);
    }
  };
  const restartQuestion = () => {
    setShowTextResponse(false);
  };

  return (
    <Box key={props.key} className={classes.container} data-qa-id={`page-${keyName}`}>
      <Grid container spacing={8.5}>
        <Grid item>
          <Typography className={classes.title} data-qa-id="question">
            {questionText}
          </Typography>
          {showTextResponse ? (
            <>
              <Typography style={{ marginBottom: '20px' }}>{subQText}</Typography>
              <TextField
                label="Provide details here"
                value={text}
                fullWidth
                helperText={`${255 - text.length} characters left`}
                onChange={limitTo255Characters}
                multiline
              />
            </>
          ) : null}
        </Grid>
        <Grid className={classes.btnContainer}>
          {!showTextResponse ? (
            <>
              <Grid item xs={12}>
                <Button
                  size="large"
                  className={classes.btnLarge}
                  onClick={() => internalResponse(buttons.button1Value || 1)}
                  data-qa-id={`option-${buttons.button1Text}`}
                >
                  {buttons.button1Text}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  size="large"
                  className={classes.btnLarge}
                  onClick={() => internalResponse(buttons.button2Value || 0)}
                  data-qa-id={`option-${buttons.button2Text}`}
                >
                  {buttons.button2Text}
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Button
                  size="large"
                  className={classes.btnLarge}
                  onClick={restartQuestion}
                  data-qa-id="option-cancel"
                  style={{ textTransform: 'none' }}
                >
                  Oops, I meant no
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  size="large"
                  className={classes.btnLarge}
                  onClick={() => response({ value: 1, details: text })}
                  data-qa-id="option-continue"
                  disabled={text.length === 0}
                >
                  Continue
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClinicalQuestion;
