import React from 'react';
import { Grid, Button } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { UserUtils } from 'utils/user-utils';
import { UserPermissions } from 'interfaces/user/Permissions';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { notifyError } from 'actions/action-notifications';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { CpmpStyles } from '../index.styles';
import { SectionTitleStyled } from '../../components/section-title';
import { SearchField } from '../../components/search-field';
import { DataTable } from './tables/data-table';
import { logger } from '../../../../winston-logger';
import { IChild } from './tables/types';
import { AddNewSupportiveCarePlansModalForm } from './modal/add-supportive-care-plans-modal';

type ICpmpProps = WithStyles<typeof CpmpStyles>;

const debounceTimeMs = 500;
const sectionTitle = 'Supportive Care Plans';
const searchFieldPlaceholder = 'Search by Brand Name, Generic Name or GPI';
const initialSearchTerm = '';

const CpmpSupportiveCarePlans = (props: ICpmpProps): JSX.Element => {
  const { classes } = props;
  const dispatch = useDispatch();

  // # region redux state
  const stateSelectedCustomerId = useTypedSelector(state => state.filters.selectedCustomerId);
  const user = useTypedSelector(state => state.auth.currentUser);
  // # endregion

  // # region useState
  const [selectedCustomerId, setSelectedCustomerId] =
    React.useState<number>(stateSelectedCustomerId);
  const [searchTerm, setSearchTerm] = React.useState<string>(initialSearchTerm);
  const [reloadDataTrigger, setReloadDataTrigger] = React.useState<Date | undefined>(undefined);
  const [reloadTrigger, setReloadTrigger] = React.useState<Date | undefined>(undefined);
  const [supportiveCareResult, setSupportiveCareResult] = React.useState<
    { id: number; medicationName: string; gpi: string; treatment: string; children: IChild[] }[]
  >([]);
  const [supportiveCarePlans, setSupportiveCarePlans] = React.useState<
    { id: number; medicationName: string; gpi: string; treatment: string; children: IChild[] }[]
  >([]);
  const DataTableContext = React.createContext(supportiveCarePlans);
  const [debouncedSearchTerm] = useDebounce<string | undefined>(searchTerm, debounceTimeMs);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [addModalOpen, setAddModalOpen] = React.useState<boolean>(false);

  // # endregion

  const userHasPermissionToAddSupportiveCarePlans = React.useMemo<boolean>(() => {
    return UserUtils.userIsPermitted(
      UserPermissions.ApplicationManagerCpmpSupportiveCarePlanAddEdit,
      selectedCustomerId,
    );
  }, [user, selectedCustomerId]);

  React.useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        if (reloadDataTrigger) {
          await new Promise(res => setTimeout(res, debounceTimeMs));
        }
        const results = await ApplicationManagerClient.fetchSupportiveCarePlans();
        let supportiveCareData = results.data.results || [];
        supportiveCareData = supportiveCareData.map(item => {
          return {
            ...item,
            children: item.children.filter(
              t => t.medicationName === item.medicationName && t.brand_name_code === 'T',
            ),
          };
        });
        setSupportiveCarePlans(supportiveCareData);
        setSupportiveCareResult(results.data.results || []);
        setSearchTerm('');
      } catch (error) {
        logger.error(error);
        dispatch(notifyError('Error while fetching supportive care plans.'));
      } finally {
        setLoading(false);
      }
    })();
  }, [reloadDataTrigger]);

  React.useEffect(() => {
    if (searchTerm.length) {
      const tempBrandSupportiveCareData = supportiveCareResult.filter(
        item =>
          item.medicationName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase().trim()) ||
          item.gpi.includes(searchTerm.trim()),
      );
      const data = tempBrandSupportiveCareData.length
        ? tempBrandSupportiveCareData
        : supportiveCareResult.filter(parent =>
            parent.children.some(
              child =>
                child.medicationName
                  .toLocaleLowerCase()
                  .includes(searchTerm.toLocaleLowerCase().trim()) && child.brand_name_code === 'T',
            ),
          );
      if (data && data.length) {
        setSupportiveCarePlans(
          data.map(item => {
            return {
              ...item,
              children: item.children.filter(
                t => t.medicationName === item.medicationName && t.brand_name_code === 'T',
              ),
            };
          }),
        );
      } else {
        const filteredData = supportiveCareResult.filter(parent =>
          parent.children.some(
            child =>
              child.medicationName
                .toLocaleLowerCase()
                .includes(searchTerm.toLocaleLowerCase().trim()) &&
              (child.brand_name_code === 'G' || child.brand_name_code === 'B'),
          ),
        );
        if (filteredData && filteredData.length) {
          setSupportiveCarePlans(
            filteredData.map(item => {
              return {
                ...item,
                children: item.children.filter(
                  t => t.medicationName === item.medicationName && t.brand_name_code === 'T',
                ),
              };
            }),
          );
        } else {
          setSupportiveCarePlans([]);
        }
      }
    } else {
      setSupportiveCarePlans(
        supportiveCareResult.map(item => {
          return {
            ...item,
            children: item.children.filter(
              t => t.medicationName === item.medicationName && t.brand_name_code === 'T',
            ),
          };
        }),
      );
    }
  }, [searchTerm]);

  const renderEnableSupportiveCarePlansModal = (): JSX.Element => {
    return (
      <AddNewSupportiveCarePlansModalForm
        open={addModalOpen}
        onCancel={() => setAddModalOpen(false)}
        onSuccess={() => setReloadDataTrigger(new Date())}
        editingSupportiveCarePlansItem={undefined}
      />
    );
  };

  const renderAddSupportiveCarePlansButton = (): JSX.Element => {
    return (
      <Button
        variant="contained"
        className={classes.addButton}
        onClick={() => setAddModalOpen(true)}
      >
        Add Supportive Care Plan
      </Button>
    );
  };

  return (
    <>
      {renderEnableSupportiveCarePlansModal()}
      {userHasPermissionToAddSupportiveCarePlans ? (
        <SectionTitleStyled
          title={`${sectionTitle}`}
          rightSideItems={[
            {
              element: renderAddSupportiveCarePlansButton(),
              key: 'add-supportive-care-plans-item-button',
            },
          ]}
        />
      ) : (
        <SectionTitleStyled title={`${sectionTitle}`} />
      )}
      <Grid container direction="column">
        <Grid container justifyContent="flex-end">
          <Grid item xs="auto">
            <Grid container spacing={0}>
              <Grid item xs="auto">
                <SearchField
                  width={48}
                  value={searchTerm}
                  onChange={setSearchTerm}
                  initialValue={initialSearchTerm}
                  placeholder={searchFieldPlaceholder}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Below the table */}
        <Grid item xs="auto">
          <DataTableContext.Provider value={supportiveCarePlans}>
            <DataTable
              searchTerm={debouncedSearchTerm}
              triggerReload={reloadTrigger}
              dataToList={supportiveCarePlans}
              userHasPermissionToEditCarePlans={userHasPermissionToAddSupportiveCarePlans}
              activeAnyField={searchTerm.length > 0}
              setReloadDataTrigger={setReloadDataTrigger}
            />
          </DataTableContext.Provider>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(CpmpStyles)(CpmpSupportiveCarePlans);
