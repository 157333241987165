import React, { useState, useRef } from 'react';
import DeliveryAddress from './delivery_address_question';
import SignatureRequired from './signature_required_question';
import DeliveryDates from './delivery_dates_question';

const deliveryQuestions = [<DeliveryAddress />, <SignatureRequired />];

const DeliveryQuestions = (props: any) => {
  const { nextQuestion, saveAnswer, isReview, onEdit, isEdit, currentAnswers, answerOptions } =
    props;
  const [answerAllQuestions, setAnswerAllQuestions] = useState(
    !!(props.questionIndex.subQ && props.questionIndex.subQ < 0),
  );
  const [subQuestionNumber, setSubQuestionNumber] = useState(
    answerAllQuestions ? 0 : props.questionIndex.subQ || 0,
  );
  if (props.answerOptions.delivery_dates && deliveryQuestions.length === 2) {
    deliveryQuestions.push(<DeliveryDates />);
  }

  const questionCompletedResponder = () => {
    if (isEdit) {
      nextQuestion();
    } else {
      nextQuestion('payment_method');
    }
  };

  const handleResponse = ({
    keyName,
    value,
  }: {
    keyName: 'preferred_shipping_address' | 'signature_required' | 'delivery_date';
    value: any;
  }) => {
    const currentZip =
      typeof currentAnswers.preferred_shipping_address?.answerOptionsIndex === 'number' &&
      currentAnswers.preferred_shipping_address.answerOptionsIndex > -1
        ? answerOptions.addresses[currentAnswers.preferred_shipping_address.answerOptionsIndex].zip
        : null;
    const newZip =
      keyName === 'preferred_shipping_address' &&
      typeof value.answerOptionsIndex === 'number' &&
      value.answerOptionsIndex > -1
        ? answerOptions.addresses[value.answerOptionsIndex].zip
        : null;
    if (subQuestionNumber < deliveryQuestions.length - 1 && (answerAllQuestions || !isEdit)) {
      if (
        keyName === 'signature_required' &&
        (currentAnswers.preferred_shipping_address.answerOptionsIndex < 0 ||
          !answerOptions.delivery_dates ||
          answerOptions.delivery_dates[currentZip].length === 0)
      ) {
        questionCompletedResponder();
      } else {
        setSubQuestionNumber(subQuestionNumber + 1);
      }
    } else if (
      isEdit &&
      keyName === 'preferred_shipping_address' &&
      (value.answerOptionsIndex < 0 ||
        answerOptions.delivery_dates[newZip].length === 0 ||
        !answerOptions.delivery_dates)
    ) {
      questionCompletedResponder();
    } else if (isEdit && keyName === 'preferred_shipping_address' && currentZip !== newZip) {
      setSubQuestionNumber(2);
    } else {
      questionCompletedResponder();
    }
    saveAnswer({ keyName, value });
  };
  const orderContainsCAMedication = currentAnswers.therapies.some(
    (therapy: any) => therapy.included && therapy.clinic_administered,
  );

  if (isReview && orderContainsCAMedication) {
    return null;
  }

  const question = deliveryQuestions[subQuestionNumber];
  if (!isReview) {
    return React.cloneElement(question, { ...props, response: handleResponse });
  }
  if (currentAnswers.pickup_or_delivery === 'pickup') {
    return null;
  }
  return (
    <>
      {deliveryQuestions.map((q: any, index: number) => {
        return React.cloneElement(q, {
          ...props,
          key: index,
          response: handleResponse,
          onEdit: () => onEdit(index),
        });
      })}
    </>
  );
};

export default DeliveryQuestions;
